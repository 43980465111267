import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Styles from "./styles";
import StartupImage from "../../Assets/images/startup1.svg";
import Arrow from "../../Assets/images/Arrow-1.svg";

const Startup = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid
          sx={Styles.startup}
        >
          <Grid container id="aboutUs" sx={Styles.topWrapper}>
            <Typography sx={Styles.heading1}>Who are we</Typography>
            <Typography sx={Styles.heading2}>
              Empower your business through innovation, technology, and lasting
              partnership with us.
            </Typography>
          </Grid>
          <Grid container spacing={2} id="startUp" sx={Styles.wrapper}>
            <Grid item md={6} lg={6} sm={12} sx={Styles.leftGrid}>
              <Box component="img" sx={Styles.logoimage} src={StartupImage} />
            </Grid>
            <Grid item md={6} lg={6} sm={12} sx={Styles.rightGrid} style={{ marginTop: "150px" }}>
              <Typography sx={Styles.rightGridheading1}>
                Why Choose us
              </Typography>
              <Grid sx={Styles.wrapper2}>
                <Typography sx={Styles.rightGridheading2}>01. </Typography>
                <Grid sx={Styles.rightGrid1}>
                  <Typography sx={Styles.rightGridheading3}>
                    Fostering Innovation
                  </Typography>
                  <Typography sx={Styles.rightGridheading4}>
                    Where Creativity Meets Excellence
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={Styles.rightParagraph}>
                We foster a collaborative and empowering atmosphere that
                inspires our diverse team for personal growth, innovation, and
                work-life balance.
              </Typography>
              <Grid sx={Styles.wrapper3}>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={Styles.bottomRight1}
                >
                  Join our team
                </Typography>
                <Box
                  component="img"
                  src={Arrow}
                  style={{ marginLeft: "10px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={6} sx={Styles.wrapper}>
            <Grid item md={6} lg={6} sm={12} sx={Styles.leftGrid1}>
              <Grid sx={Styles.wrapper2}>
                <Typography sx={Styles.leftGridheading1}>02. </Typography>
                <Grid sx={Styles.rightGrid1}>
                  <Typography sx={Styles.leftGridheading2}>
                    Nurturing Growth
                  </Typography>
                  <Typography sx={Styles.leftGridheading3}>
                    Collaborative, Empowered Workplace
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={Styles.leftParagraph}>
                We support an innovative culture where brilliance and creativity
                can flourish. Our vibrant workplace encourages individuals to
                break barriers, question accepted wisdom, and revolutionize
                industries with bold concepts.
              </Typography>
              <Grid sx={Styles.wrapper3}>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={Styles.bottomRight1}
                >
                  Join our team
                </Typography>
                <Box
                  component="img"
                  src={Arrow}
                  style={{ marginLeft: "10px" }}
                />
              </Grid>
            </Grid>
            <Grid item md={6} lg={6} sm={12} sx={Styles.rightGrid}>
              <Grid sx={Styles.wrapper2}>
                <Typography sx={Styles.rightGridheading2}>03. </Typography>
                <Grid sx={Styles.rightGrid1}>
                  <Typography sx={Styles.rightGridheading3}>
                    Real Results, Real Trust
                  </Typography>
                  <Typography sx={Styles.rightGridheading4}>
                    Trusted Partnerships Lift Businesses
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={Styles.rightParagraph}>
                We bulid Trusted partnership that drive bussiness success
                through collaboration efforts and impact results. Experience our
                reliable solutions and innovative strategies to thrive in todays
                competitive landscape.
              </Typography>
              <Grid sx={Styles.wrapper3}>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={Styles.bottomRight1}
                >
                  Join our team
                </Typography>
                <Box
                  component="img"
                  src={Arrow}
                  style={{ marginLeft: "10px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* MOBILE  and tablet*/}
      <Hidden mdUp>
        <Grid sx={Styles.startup} 
        // id="startUpMobile"
        id="startUp"
        >
          <Grid sx={Styles.mobileWrapper}>
            <Typography sx={Styles.mobileHeading1}>Who Are We</Typography>
            <Typography sx={Styles.mobileHeading2}>
              Empower your business through innovation, technology, and lasting
              partnership with us.
            </Typography>
            <Grid
              style={{
                marginTop: "60px",
                marginBottom: "30px",
                marginRight: "24px",
              }}
            >
              <Typography sx={Styles.mobileGridheading1}>
                Why Choose Us
              </Typography>
              <Grid sx={Styles.mobileWrapper2}>
                <Typography sx={Styles.mobileGridheading2}>01. </Typography>
                <Grid sx={Styles.mobileGrid1}>
                  <Typography sx={Styles.mobileGridheading3}>
                    Fostering Innovation
                  </Typography>
                  <Typography sx={Styles.mobileGridheading4}>
                    Where Creativity Meets Excellence
                  </Typography>
                </Grid>
              </Grid>

              <Typography sx={Styles.mobileParagraph}>
                We foster a collaborative and empowering atmosphere that
                inspires our diverse team for personal growth, innovation, and
                work-life balance.
              </Typography>

              <Grid sx={Styles.mobileWrapper3}>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={Styles.bottomRight1}
                >
                  Join our team
                </Typography>
                <Box
                  component="img"
                  src={Arrow}
                  style={{ marginLeft: "7px", width: "13px" }}
                />
              </Grid>
            </Grid>
            <Grid style={{ marginBottom: "30px", marginRight: "24px" }}>
              <Grid sx={Styles.mobileWrapper2}>
                <Typography sx={Styles.mobileGridheading2}>02. </Typography>
                <Grid sx={Styles.mobileGrid1}>
                  <Typography sx={Styles.mobileGridheading3}>
                    Nurturing Growth
                  </Typography>
                  <Typography sx={Styles.mobileGridheading4}>
                    Collaborative, Empowered Workplace
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={Styles.mobileParagraph}>
                We support an innovative culture where brilliance and creativity
                can flourish. Our vibrant workplace encourages individuals to
                break barriers, question accepted wisdom, and revolutionize
                industries with bold concepts.
              </Typography>
              <Grid sx={Styles.mobileWrapper3}>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={Styles.bottomRight1}
                >
                  Join our team
                </Typography>
                <Box
                  component="img"
                  src={Arrow}
                  style={{ marginLeft: "7px", width: "13px" }}
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid sx={Styles.mobileWrapper2}>
                <Typography sx={Styles.mobileGridheading2}>03. </Typography>
                <Grid sx={Styles.rightGrid1}>
                  <Typography sx={Styles.mobileGridheading3}>
                    Real Results, Real Trust
                  </Typography>
                  <Typography sx={Styles.mobileGridheading4}>
                    Trusted Partnerships Lift Businesses
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={Styles.mobileParagraph}>
                We bulid trusted partnership that drive bussiness success
                through collaboration efforts and impact results. Experience our
                reliable solutions and innovative strategies to thrive in
                today's competitive landscape.
              </Typography>
              <Grid sx={Styles.mobileWrapper3}>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={Styles.bottomRight1}
                >
                  Join our team
                </Typography>
                <Box
                  component="img"
                  src={Arrow}
                  style={{ marginLeft: "7px", width: "13px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Startup;
