import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Navbar from '../Navbar/Navbar'
import Drawer from '../Drawer/Drawer'
import logo_desk from '../../Assets/images/Logo/desktopLogo.svg'
import logo_mob from '../../Assets/images/Logo/mobileLogo.svg'
import burger_menu from '../../Assets/images/burger_menu.svg'
import styles from './styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

const Header = (props) => {

  const trigger = useScrollTrigger();

  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar sx={styles.appBar} >
          <Container maxWidth="xxl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} />
            <Toolbar disableGutters>
              <Typography variant="logo" component='a' href='/' sx={styles.logoDesk}>
                <img src={logo_desk} alt='logo' />
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none', marginLeft: '10px', } }}>
                <IconButton
                  size='large'
                  aria-label='menu'
                  onClick={() => setOpenDrawer(true)}>
                  <img src={burger_menu} alt='burger menu' />
                </IconButton>
              </Box>
              <Typography component='a' href='/' sx={styles.logoMob}>
                <img src={logo_mob} alt='logo' />
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
                <Navbar />
              </Box>
            </Toolbar>
          </Container>
        </AppBar >
      </Slide>
    </>
  )
}

export default Header
