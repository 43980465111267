import backgroundImage from '../../Assets/images/backgroundImageForStartUp.svg'
const styles = {
  startup: (theme) => ({
    marginTop: '50px',
    marginBottom: '90px',
    paddingLeft: '120px',
    paddingRight: '120px',

    // Tablet
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },

  }),
  topWrapper: (theme) => ({
    marginTop: "50px",
  }),
  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly",
    marginTop: "0px",
  }),
  wrapper2: (theme) => ({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "20px",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      marginTop: "50px",
    },
  }),

  heading1: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "18px",
    textTransform: 'capitalize',
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "120%",
    },
  }),

  heading2: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "36px",
    fontWeight: "700",
    lineHeight: "140%",

    background:
      "linear-gradient(98.09deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",

    [theme.breakpoints.down("sm")]: {
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "120%",
      letterSpacing: "0.02em",
      marginTop: "10px",

      background:
        "linear-gradient(98.09deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
  }),

  logoimage: (theme) => ({
    position: "relative",
    top: "5px",
    height: "531px",
    marginLeft: "-160px",
    width: "720px",
  }),
  leftGrid: (theme) => ({
    width: "50%",
  }),
  leftGrid1: (theme) => ({
    width: "50%",
    marginTop: "20px",
  }),
  rightGrid: (theme) => ({
    width: "50%",
    marginTop: "20px",
  }),
  rightGrid1: (theme) => ({
    marginLeft: "20px",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0px",
      marginTop: "0px",
    },
  }),

  rightGridheading1: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "120%",
    textTransform: 'capitalize',
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "120%",
      marginTop: "0px",
    },
  }),
  rightGridheading2: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "66px",
    lineHeight: "120%",
    textTransform: 'capitalize',
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
      lineHeight: "0%",
      marginTop: "20px",
    },
  }),
  rightGridheading3: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "120%",
    textTransform: 'capitalize',
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
      lineHeight: "120%",
    },
  }),
  rightGridheading4: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "120%",
    letterSpacing: '3%',
    textTransform: 'capitalize',
    [theme.breakpoints.down("lg")]: {
      fontSize: "13px",
      lineHeight: "120%",
    },
  }),
  rightParagraph: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "18px",
    marginTop: '24px',
    color: "#243251",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "160%",
      marginTop: "7px",
    },
  }),

  wrapper3: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      marginTop: "20px",
    },
  }),
  bottomRight1: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "14px",
    color: "#0359AB",
    lineHeight: '160%',
    textDecoration: 'none',
    [theme.breakpoints.down("lg")]: {
      fontFamily: "Montserrat",
      fontWeight: "600",
      fontSize: "13px",
      color: "#0359AB",
      lineHeight: "10px",
    },
  }),

  leftGridheading1: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "66px",
    lineHeight: "120%",
    textTransform: 'capitalize',
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
      lineHeight: "0%",
      marginTop: "20px",
    },
  }),

  leftGridheading2: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "120%",
    textTransform: 'capitalize',
    [theme.breakpoints.down("lg")]: {
      fontSize: "20px",
      lineHeight: "120%",
    },
  }),

  leftGridheading3: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "18px",
    letterSpacing: '3%',
    lineHeight: "120%",
    textTransform: 'capitalize',
    marginTop: '2px',
    [theme.breakpoints.down("lg")]: {
      fontSize: "13px",
    },

  }),

  leftParagraph: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "160%",
    marginTop: '24px',
    color: "#243251",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
      lineHeight: "160%",
      marginTop: "7px",
    },
  }),

  bottomLeft1: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "16px",
    color: "#0359AB",
  }),
  bottomLeftGrid: (theme) => ({
    paddingTop: "30px",
    width: "50%",
  }),
  bottomRightGrid: (theme) => ({
    paddingTop: "30px",
    justifyContent: "center",
    width: "50%",
  }),
  title: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "48px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#181E2B",
    paddingTop: "30px",
    paddingLeft: "30px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
      lineHeight: "48px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
      lineHeight: "30px",
    },
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.03px",
    },
  }),
  sideSubTitle: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "32px",
    lineHeight: "38px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#181E2B",
    paddingTop: "30px",
    paddingLeft: "30px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "32px",
      lineHeight: "38px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "-0.75px",
    },
  }),
  subTitle: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#323441",
    paddingTop: "20px",
    paddingLeft: "30px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      lineHeight: "23px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "13px",
      lineHeight: "15px",
    },
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "-1px",
      color: " #4D5460",
    },
  }),
  paragraph: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#181E2B",
    paddingTop: "20px",
    paddingLeft: "30px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: " 0.25px;",
      color: " #181E2B",
    },
  }),
  image: (theme) => ({
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  // MOBILE
  background: () => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    height: '100%',
  }),

  mobileWrapper: () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    width: "100%",
    marginBottom: '60px',
  }),
  mobileWrapper2: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    marginBottom: '10px',
  }),
  mobileWrapper3: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  }),
  mobileHeading1: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "120%",
    textTransform: 'capitalize',

  }),
  mobileHeading2: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "120%",
    letterSpacing: "0.02em",
    marginTop: "10px",

    background:
      "linear-gradient(98.09deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }),
  mobileGridheading1: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "120%",
    marginTop: "0px",
  }),
  mobileGridheading2: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "50px",
    lineHeight: "0%",
    marginTop: "24px",
    marginRight: '10px',
    textTransform: 'capitalize',
  }),
  mobileGrid1: (theme) => ({
    marginLeft: "0px",
    marginTop: "0px",
  }),
  mobileGridheading3: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "120%",
    textTransform: 'capitalize',
  }),
  mobileGridheading4: (theme) => ({
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "120%",
    marginTop: "4px",
    textTransform: 'capitalize',
  }),
  mobileParagraph: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "160%",
    marginTop: "7px",
  }),
};

export default styles;
