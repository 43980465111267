import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import styles from "./styles";
import Hidden from "@mui/material/Hidden";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";
import { HashLink } from "react-router-hash-link";

const services = [
  { title: "Cloud-Based  Software Development", link: "/servicePage#cloud" },
  { title: "Mobile App Development", link: "/servicePage#mobile" },
  { title: "DevOps and CI/CD", link: "/servicePage#devops" },
  { title: "Quality Assurance and Testing", link: "/servicePage#quality" },
  { title: "UX/UI Design", link: "/servicePage#uxui" },
];

export default function BasicMenu({ page, onClose }) {

  return (
    <>
      <Hidden smDown>
        <Typography
          sx={styles.deskbtnLink}
        >
          <Link
            component="a"
            href="/servicePage"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            {page.title}
          </Link>
        </Typography>
      </Hidden>

      {/* Mobile */}

      <Hidden smUp>
        <Accordion sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              padding: "0px 0px 0px 0px",
            }}
          >
            <Typography sx={styles.mobbtnLink2}>{page.title}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "0px 0px 0px 0px",
            }}
          >
            {services.map((item) => {
              return (
                <HashLink
                  style={{
                    textDecoration: "none",
                  }}
                  to={item.link}
                  onClick={onClose}
                >
                  <MenuItem sx={styles.subLink}>{item.title}</MenuItem>
                </HashLink>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </Hidden>
    </>
  );
}
