const styles = {
  footer: (theme) => ({
    width: "100%",
    maxHeight: "600px",
    paddingTop: "100px",
    backgroundColor: "#E0EEFF",
    paddingLeft: "120px",
    paddingRight: "120px",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "0px",
      paddingRight: "24px",
    },
  }),
  gridWrapper: (theme) => ({
    color: "#243251",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "20px",
    [theme.breakpoints.down("xxl")]: {
      marginLeft: "0px",
      marginRight: "20px",
    },

    // MOBILE
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "32px",
      paddingTop: "32px",
    },
  }),
  linkTitle: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "22.5px",
    fontWeight: "600",
    // marginBottom: "15px",
    marginTop: "25px",
    lineHeight: "36px",
    letterSpacing: "7%",
    textTransform: "capitalize",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      marginLeft: "0px",
    },
  }),
  itemList: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "500",
    marginBottom: "15px",
    lineHeight: "24px",
    letterSpacing: " 0.03em",
    marginLeft: "-15px",
    color: "#243251",
    cursor: "pointer",
    textTransform: "capitalize",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.42px",
      // marginLeft: '0px',
      marginBottom: "0px",
    },
  }),
  mobileSubitemList: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "300",
    marginBottom: "15px",
    lineHeight: "24px",
    letterSpacing: " 0.03em",
    // marginLeft: '10px',
    color: "#243251",
    textDecoration: "none",
  }),
  list: (theme) => ({
    marginLeft: "0px",
    marginTop: "10px",
  }),
  linkSubTitle: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: " 0.03em",
    color: "#243251",
    marginBottom: "2px",
    textTransform: "capitalize",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      // marginLeft: '10px',
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.42px",
    },
  }),
  itemListSubTitle: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    color: "#243251",
    textDecoration: "none",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "400",
      fontSize: "16px",
      letterSpacing: "0.42px",
      marginLeft: "20px",
    },
  }),
  followUsImage: (theme) => ({
    height: "24px",
    width: "24px",
    margin: "5px 5px 0px 5px",
  }),
  logoImage: (theme) => ({
    height: "36px",
    width: "186px",
  }),
  gridBottom: (theme) => ({
    backgroundColor: "#E0EEFF",
    justifyContent: "center",
    alignItem: "center",
    paddingBottom: "50px",
    paddingTop: "50px",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
    },
  }),
  copyright: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: " 0.04em",
    color: "#243251",
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
      alignItem: "left",
    },
  }),
  // MOBILE
  mobileFooter: (theme) => ({
    padding: " 60px 62px 10px 25px",
    margin: "0",
    width: "100%",
    backgroundColor: "#E0EEFF",
  }),
  mobileWrapper: (theme) => ({
    color: "#243251",
    backgroundColor: "#E0EEFF",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "24px",
    },
  }),
};
export default styles;
