const styles = {
    hero: (theme) => ({
        marginTop: '160px',
        marginBottom: '100px',
        paddingLeft: '120px',
        paddingRight: '120px',

        // Tablet
        [theme.breakpoints.down('lg')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    }),
    leftGrid: (theme) => ({
        marginTop: '60px',
    }),
    rightGrid: (theme) => ({
        marginTop: '10px',
        maxWidth: '766px',
    }),
    photo: (theme) => ({
        height: '100%',
        marginLeft: '-150px',
        display: 'block',
        [theme.breakpoints.down('lg')]: {
            marginLeft: '-100px',
            height: '100%',
        },
    }),
    mobilePhoto: (theme) => ({
        marginLeft: '0',
        height: '100%',
        width: '100%',
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            width: '1145.654px',
            height: '413.096px',
        },
    }),
    title: (theme) => ({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '55px',
        lineHeight: '120%',
        display: 'flex',
        color: '#243251',
        alignItems: 'center',
        letterSpacing: ' -0.01em',
        [theme.breakpoints.down('lg')]: {
            fontSize: '32px',
            lineHeight: '38px',
        },
        // MOBILE
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
            lineHeight: '38px',
        },
    }),
    typewritterAnimation: (theme) => ({
        fontFamily: 'Poppins',
        fontStyle: 'italic',
        fontWeight: 600,
        fontSize: '55px',
        lineHeight: '120%',
        display: 'flex',
        color: '#243251',
        alignItems: 'center',
        letterSpacing: ' -0.01em',
        [theme.breakpoints.down('lg')]: {
            fontSize: '32px',
            lineHeight: '38px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '32px',
            lineHeight: '38px',
        },

    }),
    body: (theme) => ({
        margin: '30px 80px 30px 0px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: "22.5px",
        lineHeight: "29px",
        display: "flex",
        color: "#243251",
        alignItems: 'center',
    }),

    aboutButton: (theme) => ({
        /* My_button */
        fontFamily: "Poppins",
        fontStyle: 'normal',
        fontWeight: 500,
        width: '180px',
        height: '48px',
        textTransform: 'none',
        /* blue */
        background: '#0359AB',
        boxShadow: ' 0px 5.70173px 19.5488px rgba(20, 20, 43, 0.05)',
        borderRadius: '8px',
        fontSize: '18px',
        lineHeight: '22px',
        color: '#F5F5F5',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
        '&:hover': {
            background: theme.palette.slateBlue.main,
        },
    }),
    subButton: (theme) => ({
        marginTop: '20px',
        marginLeft: '-40px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: ' 18px',
        lineHeight: '16px',
        display: 'flex',
        color: '#0359AB',
        width: '280px',
        textDecoration: 'none',
    }),

    // MOBILE
    wrapper: (theme) => ({
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginTop: '60px',
        marginLeft: '24px',
        marginRight: '24px',
        marginBottom: '15px',
    }),
    mobileHero: (theme) => ({
        width: '100%',
        maxWidth: '1240px',
        paddingLeft: '0px',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginBottom: '90px',
        marginTop: '90px'
    }),
    mobileGrid: (theme) => ({
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '10px',
        marginBottom: '25px',

    }),
    mobileTitle: (theme) => ({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        display: 'flex',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '120%',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        width: '100%',
        color: '#243251',
        margin: '5px 5px 5px 5px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '55px',
            lineHeight: '120%', /* 66px */
            letterSpacing: '-0.55px',
        },
    }),
    typewritterAnimationMobile: (theme) => ({
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        display: 'flex',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '120%',
        color: '#243251',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        width: '100%',
        margin: '5px 5px 5px 5px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '55px',
            lineHeight: '120%', /* 66px */
            letterSpacing: '-0.55px',
        },
    }),

    mobileBody: (theme) => ({
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '170%',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        margin: '5px 5px 5px 5px',
        color: '#323441',
        [theme.breakpoints.up('sm')]: {
            fontSize: '22.5px',
            lineHeight: '145%', /* 32.625px */
        },
    }),

    aboutButtonMobile: (theme) => ({
        fontFamily: "Montserrat",
        fontStyle: 'normal',
        fontWeight: 600,
        width: '180px',
        height: '48px',
        textTransform: 'none',
        marginTop: '25px',
        marginBottom: '25px',
        /* blue */
        background: '#0359AB',
        boxShadow: ' 0px 5.70173px 19.5488px rgba(20, 20, 43, 0.05)',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#F5F5F5',
        display: 'flex',
        '&:hover': {
            background: theme.palette.slateBlue.main,
        },

    }),
    subButtonMobile: (theme) => ({
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: ' 16px',
        lineHeight: '16px',
        display: 'flex',
        color: '#0359AB',
        width: '280px',
        textDecoration: 'none',
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginTop: '10px',
        marginBottom: '10px',
    }),
}

export default styles