const styles = {
  service: (theme) => ({
    marginTop: '50px',
    marginBottom: '100px',
    paddingLeft: '120px',
    paddingRight: '120px',

    // Tablet
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },

  }),

  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: "100px",
    paddingBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginLeft: "24px",
    },
  }),

  leftGrid: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
    },
  }),

  ipadleftGrid: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  }),
  leftGridHeading1: (theme) => ({
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "120%",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "120%",
    },
  }),
  leftGridHeading2: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "36px",
    fontWeight: "700",
    lineHeight: "140%",
    background:
      "linear-gradient(98.09deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontFamily: "Montserrat",
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "140%",
      background:
        "linear-gradient(98.09deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      marginTop: "20px",
    },
  }),
  rightGrid: (theme) => ({
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  }),

  ipadrightGrid: (theme) => ({
    width: "100%",
  }),

  rightGridWrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "30px",
    },
  }),

  circleImage: (theme) => ({
    height: "45px",
    width: "45px",
    position: "relative",
    top: "13px",
    [theme.breakpoints.down("sm")]: {
      top: "0px",
    },
  }),

  ipadcircleImage: (theme) => ({
    height: "45px",
    width: "45px",
    position: "relative",
    top: "20px",
    [theme.breakpoints.down("sm")]: {
      top: "0px",
    },
  }),
  rightGridHeading3: (theme) => ({
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "120%",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "120%",
      marginTop: "5px",
    },
  }),
  rightGridHeading4: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "6px",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "160%",
      marginTop: "5px",
    },
  }),

  wrapper1: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "70px",
    paddingBottom: "100px",
  }),

  ipadwrapper1: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
    paddingBottom: "50px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  }),

  wrapper4: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "70px",
    paddingBottom: "100px",
  }),

  card: (theme) => ({
    height: "430px",
    width: "350px",

    padding: "20px 30px 30px 20px",
    borderRadius: "20px",

    boxShadow:
      " -20px -20px 50px #F1F7FF, -7px 11px 40px rgba(173, 175, 176, 0.35)",
    [theme.breakpoints.down("xl")]: {
      marginTop: "20px",
      width: "300px",
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
      width: "100%",
    },
  }),
  card1: (theme) => ({
    height: "430px",
    width: "350px",

    padding: "20px 30px 30px 20px",
    borderRadius: "20px",

    boxShadow:
      " -20px -20px 50px #F1F7FF, -7px 11px 40px rgba(173, 175, 176, 0.35)",
    [theme.breakpoints.down("xl")]: {
      marginTop: "20px",
      width: "300px",
      height: "100%",
    },
  }),

  ipadcard: (theme) => ({
    height: "350px",
    width: "276px",
    padding: "20px 10px 10px 10px",
    borderRadius: "20px",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "5px",
    marginRight: "5px",
    background: '#FFF',
    boxShadow: '-6.33784px 9.95946px 36.21622px 0px rgba(173, 175, 176, 0.35), -18.10811px -18.10811px 45.27027px 0px #F1F7FF',
    // boxShadow:" -20px -20px 50px #F1F7FF, -7px 11px 40px rgba(173, 175, 176, 0.35)",
    [theme.breakpoints.down("xl")]: {
      marginTop: "20px",
    },
  }),

  serviceImage: (theme) => ({
    height: "80px",
  }),
  ipadserviceImage: (theme) => ({
    height: "60px",
  }),

  serviceHeading1: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "22.5px",
    fontWeight: "700",
    lineHeight: "120%",
    marginTop: "6px",
    [theme.breakpoints.down("lg")]: {
      fontFamily: "Montserrat",
      fontSize: "21px",
      fontWeight: "600",
      lineHeight: "120%",
      marginTop: "6px",
    },
  }),

  ipadserviceHeading1: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "700",
    fontStyle: "normal",
    lineHeight: "120%",
    marginTop: "6px",
  }),

  serviceHeading2: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "12px",
    [theme.breakpoints.down("lg")]: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "160%",
      marginTop: "12px",
    },
  }),

  ipadserviceHeading2: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    marginTop: "12px",
  }),

  wrapper2: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
  }),

  learnMore: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "30px",
    color: "#0359AB",
    textDecoration: "none",
  }),

  ipadlearnMore: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    fontStyle: "normal",
    lineHeight: "140%",
    marginTop: "30px",
    color: "#0359AB",
    textDecoration: "none",
  }),

  wrapper3: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "430px",
    width: "350px",
    paddingRight: "0px",
  }),

  ipadwrapper3: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "276px",
    paddingRight: "0px",
  }),

  heading: (theme) => ({
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "140%",
    background:
      "linear-gradient(150deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }),

  ipadheading: (theme) => ({
    fontFamily: "Poppins",
    fontSize: "22.5px",
    fontWeight: "700",
    lineHeight: "140%",
    fontStyle: "normal",
    background:
      "linear-gradient(150deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  }),

  // Mobile
  mobileServices: (theme) => ({
    paddingLeft: "24px",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    textAlign: "left",
  }),
  mobileWrapper: (theme) => ({
    paddingBottom: "60px",
    justifyContent: "left",
    alignItems: "left",
    gap: "35px",
    width: "100%",
    textAlign: "left",
  }),
  mobileWrapper2: (theme) => ({
    display: "flex",
    flexDirection: "row",
    marginBottom: "60px",
  }),
  mobileLearnMore: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "160%",
    color: "#0359AB",
    textDecoration: "none",
  }),
  mobileSlider: (theme) => ({
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: '100%',
    padding: '5px',
    whiteSpace: "pre-wrap",
  }),
  mobileTitle: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "120%",
    backgroundColor: 'red',
    background:
      "linear-gradient(98.09deg, #7BCEC4 5.76%, #4EB1C9 11.44%, #339DCA 23.24%, #1C75BC 33.76%, #0674C9 50.64%, #1F67B3 70.88%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    marginBottom: "30px",
  }),
  mobileBody: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "left",
    letterSpacing: "0.5px",
    color: "#323441",
  }),
  customSlider: (theme) => ({
    background: "#FDFDFD",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  }),
  customArrow: (theme) => ({
    padding: "12px",
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#0359AB",
    color: "#F5F5F5",
    backgroundColor: "#0359AB",
    fontSize: "40px",
    fontStyle: "bold",
  }),
  rightArrow: (theme) => ({
    alignItems: "right",
    justifyContent: "right",
    textAlign: "right",
    marginTop: "10px",
  }),
};

export default styles;
