import React from "react";
import Typewriter from "typewriter-effect";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import scrollTo from "gatsby-plugin-smoothscroll";
import Button from "@mui/material/Button";
import styles from "./styles";
import heroImage from "../../Assets/images/heroImageWebsite.svg";
import heroImageMobile from "../../Assets/images/heroMobile.svg";

const Hero = () => {
  return (
    <>
      {/* Desktop */}
      <Hidden mdDown>
        <Grid container spacing={2} direction="row" sx={styles.hero}>
          <Grid item lg={8} md={6} sx={styles.leftGrid}>
            <Typography sx={styles.title}>Your&nbsp; </Typography>
            <Typography sx={styles.typewritterAnimation}>
              <Typewriter
                options={{
                  strings: [
                    "technology service",
                    "ERP solutions",
                    "support service",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </Typography>
            <Typography sx={styles.title}>in one stop</Typography>
            <Typography sx={styles.body}>
              Unlock the power of technology with Updatus! Choose our team of
              professionals for cutting-edge technological solutions that drive
              your digital success.
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  sx={styles.aboutButton}
                  onClick={() => scrollTo("#startUp", "nearest")}
                >
                  About Us
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={6} >
            {/* <Player
              autoplay
              loop
              src="https://lottie.host/e6981137-99f6-4055-8445-44cf4c2365b0/Q5X0Pwvcdn.json"
              style={{
                height: '445px',
                width: '900px',
                marginLeft: '-200px',
              }}
            >
            </Player> */}

            <Box component="img" src={heroImage} sx={styles.photo} />
          </Grid>
        </Grid >
      </Hidden >

      {/* Mobile  and Tablet*/}
      <Hidden mdUp >
        <Grid container sx={styles.mobileHero}>
          <Grid item xs={12}>
            <Box
              component="img"
              src={heroImageMobile}
              sx={styles.mobilePhoto}
            />
          </Grid>
          <Grid item xs={12} sx={styles.wrapper}>
            <Typography sx={styles.mobileTitle}>Your</Typography>
            <Typography sx={styles.typewritterAnimationMobile}>
              <Typewriter
                options={{
                  strings: [
                    "technology service",
                    "ERP solutions",
                    "support service",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </Typography>
            <Typography sx={styles.mobileTitle}>in one stop</Typography>
          </Grid>
          <Grid item xs={12} sx={styles.mobileGrid}>
            <Typography sx={styles.mobileBody}>
              Unlock the power of technology with Updatus! Choose our team of
              professionals for cutting-edge technological solutions that drive
              your digital success.
            </Typography>
            <Button
              sx={styles.aboutButtonMobile}
              onClick={() => scrollTo("#startUp", "nearest")}
            >
              About Us
            </Button>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Hero;
