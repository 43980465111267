import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import styles from "./styles";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import Logo from "../../Assets/images/Logo/desktopLogo.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import scrollTo from "gatsby-plugin-smoothscroll";
import IconButton from "@mui/material/IconButton";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const services = [
  {
    title: "Cloud-based Software development",
    link: "/servicePage#cloud",
    id: "1",
  },
  { title: "Mobile App Development", link: "/servicePage#mobile", id: "2" },
  { title: "DevOps and CI/CD", link: "/servicePage#devops", id: "3" },
  {
    title: "Quality Assurance and Testing",
    link: "/servicePage#quality",
    id: "4",
  },
  { title: "UX/UI Design", link: "/servicePage/#uxui", id: "5" },
];

const company = [
  { title: "About us", link: "/#startUp", id: "1" },
  { title: "Our value", link: "/#value", id: "2" },
  // { title: "Testimony", link: "#Testimony" },
];

const Footer = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid container sx={styles.footer}>
          <Container maxWidth="xxl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid item xs={12} sm={12} md={12} sx={styles.gridWrapper}>
              {/* Grid 1 */}
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                sx={{ paddingTop: "24px", paddingRight: 0 }}
              >
                <Typography
                  variant="logo"
                  component="a"
                  href="/"
                  sx={styles.logoImage}
                >
                  <img src={Logo} alt="logo" />
                </Typography>
              </Grid>
              {/* Grid 2 */}
              <Grid item xs={3} sm={3} md={3}>
                <Box>
                  <Typography sx={styles.linkTitle}>COMPANY</Typography>
                  <List sx={styles.list}>
                    {company.map((item, index) => {
                      return (
                        <HashLink
                          to={item.link}
                          style={{ textDecoration: "none" }}
                          scroll={(el) =>
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "nearest",
                            })
                          }
                        >
                          <ListItem sx={styles.itemList} key={item.id}>
                            {item.title}
                          </ListItem>
                        </HashLink>

                        // <ListItem
                        //   onClick={() => scrollTo(item.link, "nearest")}
                        //   sx={styles.itemList}
                        //   key={index}
                        // >
                        //   {item.title}
                        // </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
              {/* Grid 3 */}
              <Grid item xs={4} sm={4} md={4}>
                <Box>
                  <Typography sx={styles.linkTitle}>SERVICES</Typography>
                  <List sx={styles.list}>
                    {services.map((item, index) => {
                      return (
                        <HashLink
                          to={item.link}
                          style={{ textDecoration: "none" }}
                          // scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start' })}
                        >
                          <ListItem sx={styles.itemList} key={item.id}>
                            {item.title}
                          </ListItem>
                        </HashLink>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
              {/* Grid 4 */}
              <Grid item xs={2} sm={2} md={2}>
                <Typography sx={styles.linkTitle}>GET IN TOUCH</Typography>
                <Box style={{ marginTop: "20px" }}>
                  <Box style={{ marginBottom: "20px", marginTop: "10px" }}>
                    <Typography sx={styles.linkSubTitle}>Hire us</Typography>
                    <Typography
                      variant="mail"
                      component="a"
                      href="mailto:info@updatus.com"
                      sx={styles.itemListSubTitle}
                    >
                      info@updatus.com
                    </Typography>
                  </Box>

                  <Box style={{ marginBottom: "20px", marginTop: "10px" }}>
                    <Typography sx={styles.linkSubTitle}>
                      Join Our Team
                    </Typography>
                    <Typography
                      variant="mail"
                      component="a"
                      href="mailto:carrers@updatus.com"
                      sx={styles.itemListSubTitle}
                    >
                      carrers@updatus.com
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography sx={styles.linkTitle}>Follows us</Typography>
                  <Box>
                    <IconButton
                      aria-label="twitter.com"
                      onClick={() =>
                        window.open("https://twitter.com/Updatus_")
                      }
                    >
                      <TwitterIcon sx={styles.followUsImage} />
                    </IconButton>
                    <IconButton
                      aria-label="Linkedin.com"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/updatus-inc/"
                        )
                      }
                    >
                      <LinkedInIcon sx={styles.followUsImage} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={styles.gridBottom}>
              <Typography sx={styles.copyright}>
                Copyright © 2023 Updatus. All Right Reserved
              </Typography>
            </Grid>
          </Container>
        </Grid>
      </Hidden>

      {/* MOBILE */}
      <Hidden mdUp>
        <Grid container xs={12} sx={styles.mobileFooter}>
          <Box sx={styles.mobileWrapper}>
            <Box style={{ marginBottom: "50px", marginTop: "10px" }}>
              {/* <Box sx={styles.logoImage} component="img" src={Logo} /> */}
              <Typography
                variant="logo"
                component="a"
                href="/"
                sx={styles.logoImage}
              >
                <img src={Logo} alt="logo" />
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.linkTitle}>COMPANY</Typography>
              <List>
                {company.map((item, index) => {
                  return (
                    <HashLink to={item.link} style={{ textDecoration: "none" }}>
                      <ListItem
                        // onClick={() => scrollTo(item.link)}
                        sx={styles.itemList}
                        key={index}
                      >
                        {item.title}
                      </ListItem>
                    </HashLink>
                  );
                })}
              </List>
            </Box>
            <Box>
              <Typography sx={styles.linkTitle}>SERVICES</Typography>
              <List>
                {services.map((item, index) => {
                  return (
                    <HashLink to={item.link} style={{ textDecoration: "none" }}>
                      <ListItem sx={styles.itemList} key={index}>
                        {item.title}
                      </ListItem>
                    </HashLink>
                  );
                })}
              </List>
            </Box>
            <Box>
              <Typography sx={styles.linkTitle}>GET IN TOUCH</Typography>
              <Box style={{ marginBottom: "20px", marginTop: "10px" }}>
                <Typography sx={styles.linkSubTitle}>Hire us</Typography>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:info@updatus.com"
                  sx={styles.mobileSubitemList}
                >
                  info@updatus.com
                </Typography>
              </Box>
              <Box style={{ marginBottom: "20px", marginTop: "10px" }}>
                <Typography sx={styles.linkSubTitle}>Join our team</Typography>
                <Typography
                  variant="mail"
                  component="a"
                  href="mailto:carrers@updatus.com"
                  sx={styles.mobileSubitemList}
                >
                  carrers@updatus.com
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.linkTitle}>Follows us</Typography>
                <Box>
                  <IconButton
                    aria-label="twitter.com"
                    onClick={() => window.open("https://twitter.com/Updatus_")}
                  >
                    <TwitterIcon sx={styles.followUsImage} />
                  </IconButton>
                  <IconButton
                    aria-label="Linkedin.com"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/updatus-inc/"
                      )
                    }
                  >
                    <LinkedInIcon sx={styles.followUsImage} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Box item xs={12} sm={12} md={12} sx={styles.gridBottom}>
              <Typography sx={styles.copyright}>
                Copyright © 2023 Updatus. All Right Reserved
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Hidden>
    </>
  );
};

export default Footer;
