import React from "react";
import serviceStyle from "./serviceStyle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Cloud1 from "../../Assets/images/WebDevlopment-Image/Cloud1.svg";
import Cloud2 from "../../Assets/images/WebDevlopment-Image/Cloud2.svg";
import Cloud3 from "../../Assets/images/WebDevlopment-Image/Cloud3.svg";
import Angular from "../../Assets/images/tools/Angular.svg";
import React1 from "../../Assets/images/tools/Reactnew.png";
import Node from "../../Assets/images/tools/Nodejs.svg";
import CHash from "../../Assets/images/tools/C.svg";
import Java from "../../Assets/images/tools/Java.svg";
import Python from "../../Assets/images/tools/Python.svg";
import HTML from "../../Assets/images/tools/html.svg";
import CSS from "../../Assets/images/tools/css.svg";
import Javascript from "../../Assets/images/tools/Javascript.svg";
import Php from "../../Assets/images/tools/Php.svg";
import CloudLogo from "../../Assets/images/ServiceIcons/cloud.png";
import mobileStyle from "../mobileStyle";
import Hidden from "@mui/material/Hidden";

const Cloud = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid id="cloud" sx={serviceStyle.reSizeCloud}>
          {/* Top */}
          <Grid item xs={12}>
            <Typography sx={serviceStyle.heading1}>Services</Typography>
            <Typography sx={serviceStyle.heading2}>What Can We Do</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            {/* TOP */}
            <Grid sx={serviceStyle.wrapper} item xs={12} lg={6}>
              {/* Left */}
              <Box sx={serviceStyle.cardLeft}>
                <CardContent sx={serviceStyle.cardContentLeft}>
                  <Typography sx={serviceStyle.leftHeading1}>
                    SCALABLE AND AGILE
                  </Typography>
                  <Typography sx={serviceStyle.leftHeading2}>
                    Cloud-based software development
                  </Typography>
                  <Typography sx={serviceStyle.leftHeading4}>
                    We architect cloud-based software solutions that seamlessly
                    scale to accommodate growing needs, while approaching
                    development with an agile workflow to ensure flexibility and
                    adaptability.
                  </Typography>
                </CardContent>
              </Box>

              {/* Right*/}
              <Box
                sx={serviceStyle.rightImage}
                component="img"
                src={CloudLogo}
              />
            </Grid>

            {/* Bottom */}
            <Grid sx={serviceStyle.wrapper} item xs={12}>
              <Grid style={{ width: "33%" }}>
                <Box component="img" sx={serviceStyle.image} src={Cloud1} />
                <Typography sx={serviceStyle.heading3}>
                  Seamless Integration
                </Typography>
                <Typography sx={serviceStyle.heading4}>
                  Our seamless integration process prepares you for a
                  hassle-free interoperation experience.
                </Typography>
              </Grid>
              <Grid style={{ width: "33%" }}>
                <Box component="img" src={Cloud2} sx={serviceStyle.image} />
                <Typography sx={serviceStyle.heading3}>
                  Dependable Infrastructure
                </Typography>
                <Typography sx={serviceStyle.heading4}>
                  Our services ensure your data protection, high availability,
                  and uninterrupted performance.
                </Typography>
              </Grid>
              <Grid style={{ width: "33%" }}>
                <Box component="img" src={Cloud3} sx={serviceStyle.image} />
                <Typography sx={serviceStyle.heading3}>
                  Optimal Cost Efficiency
                </Typography>
                <Typography sx={serviceStyle.heading4}>
                  The Cloud-based approach reduces infrastructure costs and
                  optimizes resource utilization.
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Typography sx={serviceStyle.toolHeading}>
                Tools we expert in
              </Typography>
              <Grid sx={serviceStyle.toolWrapper}>
                <Box component="img" src={Angular} />
                <Box component="img" src={React1} />
                <Box component="img" src={Python} />
                <Box component="img" src={Node} />
                <Box component="img" src={Java} />
                <Box component="img" src={CSS} />
                <Box component="img" src={HTML} />
                <Box component="img" src={Javascript} />
                <Box component="img" src={Php} />
                <Box component="img" src={CHash} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}

      <Hidden mdUp smDown>
        <Grid id="cloud" sx={serviceStyle.serviceWrapper}>
          <Grid item xs={12}>
            <Typography sx={serviceStyle.iPadheading1}>Services</Typography>
            <Typography sx={serviceStyle.iPadheading2}>
              What Can We Do
            </Typography>
          </Grid>
          <Grid>
            <Box
              sx={serviceStyle.iPadrightImage}
              component="img"
              src={CloudLogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              SCALABLE AND AGILE
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              Cloud-based software development
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We architect cloud-based software solutions that seamlessly scale
              to accommodate growing needs, while approaching development with
              an agile workflow to ensure flexibility and adaptability.
            </Typography>
          </Grid>
          <Grid sx={serviceStyle.iPadwrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Cloud1} />
              <Typography sx={serviceStyle.heading3}>
                Seamless Integration
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our seamless integration process prepares you for a hassle-free
                interoperation experience.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Cloud2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Dependable Infrastructure
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our services ensure your data protection, high availability, and
                uninterrupted performance.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Cloud3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Optimal Cost Efficiency
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                The Cloud-based approach reduces infrastructure costs and
                optimizes resource utilization.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.ipadWrapper1}>
              <Grid
                sx={serviceStyle.iPadWrapper2}
                container
                columnSpacing={{ xs: 1, sm: 4, md: 8 }}
              >
                <Grid item component="img" src={Angular} />
                <Grid item component="img" src={React1} />
                <Grid item component="img" src={Python} />
                <Grid item component="img" src={Node} />
                <Grid item component="img" src={Java} />
              </Grid>
              <Grid
                sx={serviceStyle.iPadWrapper2}
                container
                columnSpacing={{ xs: 1, sm: 4, md: 8 }}
              >
                <Grid item component="img" src={CSS} />
                <Grid item component="img" src={HTML} />
                <Grid item component="img" src={Javascript} />
                <Grid item component="img" src={Php} />
                <Grid item component="img" src={CHash} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* mobile */}
      <Hidden smUp>
        <Grid id="cloud" sx={mobileStyle.mobileWrapper}>
          <Grid item xs={12}>
            <Typography sx={serviceStyle.heading1}>Services</Typography>
            <Typography sx={serviceStyle.heading2}>What Can We Do</Typography>
          </Grid>
          <Grid>
            <Box sx={serviceStyle.rightImage} component="img" src={CloudLogo} />
            <Typography sx={serviceStyle.leftHeading1}>
              SCALABLE AND AGILE
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              Cloud-based software development
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We architect cloud-based software solutions that seamlessly scale
              to accommodate growing needs, while approaching development with
              an agile workflow to ensure flexibility and adaptability.
            </Typography>
          </Grid>
          <Grid sx={serviceStyle.wrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Cloud1} />
              <Typography sx={serviceStyle.heading3}>
                Seamless Integration
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our seamless integration process prepares you for a hassle-free
                interoperation experience.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Cloud2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Dependable Infrastructure
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our services ensure your data protection, high availability, and
                uninterrupted performance.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Cloud3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Optimal Cost Efficiency
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                The Cloud-based approach reduces infrastructure costs and
                optimizes resource utilization.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>

            <Grid sx={serviceStyle.mobileWrapper1}>
              <Grid
                sx={serviceStyle.mobileWrapper2}
                container
                columnSpacing={{ xs: 1 }}
              >
                <Grid item component="img" src={Angular} />
                <Grid item component="img" src={React1} />
                <Grid item component="img" src={Python} />
                <Grid item component="img" src={Node} />
                <Grid item component="img" src={Java} />
                <Grid item component="img" src={CSS} />
                <Grid item component="img" src={HTML} />
                <Grid item component="img" src={Javascript} />
                <Grid item component="img" src={Php} />
                <Grid item component="img" src={CHash} />

              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Cloud;
