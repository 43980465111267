const styles = {
  client: (theme) => ({
    marginTop: "50px",
    marginBottom: "180px",
    paddingLeft: "120px",
    paddingRight: "120px",

    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  }),

  wrapper: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "36px",
    fontWeight: "700",
    lineHeight: "140%",
    marginTop: "50px",
    marginBottom: "60px",
    color: "#243251",
    [theme.breakpoints.down("md")]: {
      justifyContent: "left",
      alignItems: "left",
      textAlign: "left",
    },
  }),
  mobileWrapper: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    justifyContent: "left",
    alignItems: "left",
    textAlign: "left",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "120%",
    letterSpacing: "0.64px",
    marginTop: "0px",
    marginBottom: "30px",
    color: "#243251",
    marginLeft: "10px",
  }),

  clients: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "50px",
    marginLeft: "20px",
    marginRight: "20px",
    flexWrap: "wrap",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      marginLeft: "0px",
      marginRight: "0px",
      gap: "0px",
      justifyContent: "space-evenly",
    },
  }),

  clientLogo: (theme) => ({
    width: "150px",
    height: "30px",
    marginRight: "5px",
    margin: "10px",
  }),
  ipadclientLogo: (theme) => ({
    width: "90px",
    height: "30px",
    marginRight: "5px",
    margin: "10px",
  }),
  mobileClientLogo: (theme) => ({
    width: "100px",
    height: "30px",
    margin: "10px",
  }),
  famoeatsLogo: (theme) => ({
    width: "50px",
    height: "30px",
    marginRight: "5px",
    margin: "10px",
  }),
  mobileClient: (theme) => ({
    marginLeft: "14px",
    marginTop: '90px',
    marginBottom: "90px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "26px",
    },
  }),
  mobileClients: (theme) => ({
    marginTop: "10px",
    marginBottom: "10px",
    flexDirection: "row",
  }),
  ipadClients: (theme) => ({
    direction: "row",
    flexWrap: "wrap",
    marginLeft: "-10px"
  }),
};
export default styles;
