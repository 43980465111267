import React from "react";
import { Link, Typography } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import DropDown from "./DropDown";
import styles from "./styles";

const BtnNav = ({ page, onClose }) => {
  return (
    <>
      {page.arrow ? (
        <DropDown page={page} onClose={onClose} />
      ) : (
        <>
          {/* Desktop */}
          <Hidden smDown>
            <Typography
              component="a"
              href="/"
              sx={styles.deskbtnLink}
              onClick={onClose}
            >
              {page.title}
            </Typography>
          </Hidden>

          {/* Mobile */}
          <Hidden smUp>
            <Link
              component="a"
              href={page.link}
              sx={styles.mobbtnLink1}
              onClick={onClose}
            >
              {page.title}
            </Link>
          </Hidden>
        </>
      )}
    </>
  );
};

export default BtnNav;