import React from "react";
import serviceStyle from "./serviceStyle";
import mobileStyle from "../mobileStyle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Figma from "../../Assets/images/tools/Figma.svg";
import Diamond from "../../Assets/images/tools/Diamond.svg";
import Ai from "../../Assets/images/tools/Ai.svg";
import Xd from "../../Assets/images/tools/Xd.svg";
import M from "../../Assets/images/tools/M.svg";
import W from "../../Assets/images/tools/W.svg";
import Design1 from "../../Assets/images/WebDevlopment-Image/Design1.svg";
import Design2 from "../../Assets/images/WebDevlopment-Image/Design2.svg";
import Design3 from "../../Assets/images/WebDevlopment-Image/Design3.svg";
import UXUILogo from "../../Assets/images/ServiceIcons/uxui.png";
import Hidden from "@mui/material/Hidden";

const UXUI = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid
          id="uxui"
          sx={serviceStyle.reSizeUxui}
        >
          {/* Top */}
          <Grid
            item
            xs={12}
            style={{ paddingLeft: "0px", paddingRight: "0px", }}
          >
            <Grid sx={serviceStyle.wrapper} item xs={12}>
              {/* Left */}
              <Box sx={serviceStyle.cardLeft}>
                <CardContent sx={serviceStyle.cardContentLeft}>
                  <Typography sx={serviceStyle.leftHeading1}>
                    DESIGN EMPOWERING SUCCESS
                  </Typography>
                  <Typography sx={serviceStyle.leftHeading2}>
                    UX/UI Design
                  </Typography>

                  <Typography sx={serviceStyle.leftHeading4}>
                    We craft user-friendly interface designs for cloud-based
                    software solutions, combining aesthetic appeal with
                    data-driven strategies customized to meet your business
                    needs.
                  </Typography>
                </CardContent>
              </Box>
              {/* Right*/}
              <Box
                sx={serviceStyle.rightImage}
                component="img"
                src={UXUILogo}
              />
            </Grid>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapper}>
            <Grid style={{ width: "33%" }}>
              <Box component="img" sx={serviceStyle.image} src={Design1} />
              <Typography sx={serviceStyle.heading3}>
                Happy users, thriving businesses
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our user-centered designs improve your user engagement,
                conversion rates, and customer loyalty.
              </Typography>
            </Grid>
            <Grid style={{ width: "33%" }}>
              <Box component="img" src={Design2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Responsive design
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We make mobile-first responsive designs that allow users to
                access your digital products anywhere, anytime.
              </Typography>
            </Grid>
            <Grid style={{ width: "33%" }}>
              <Box component="img" src={Design3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Data-driven strategy
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                With data-driven research and insights, we create measurable,
                impact design experiences..
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Typography sx={serviceStyle.toolHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.toolWrapper}>
              <Box component="img" src={Figma} />
              <Box component="img" src={Diamond} />
              <Box component="img" src={Ai} />
              <Box component="img" src={Xd} />
              <Box component="img" src={M} />
              <Box component="img" src={W} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}

      <Hidden mdUp smDown>
        <Grid id="uxui" sx={mobileStyle.ipadWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.iPadrightImage}
              component="img"
              src={UXUILogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              DESIGN EMPOWERING SUCCESS
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              UX/UI Design
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We craft user-friendly interface designs for cloud-based
              software solutions, combining aesthetic appeal with data-driven
              strategies customized to meet your business needs.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.iPadwrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Design1} />
              <Typography sx={serviceStyle.heading3}>
                Happy users, thriving businesses
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our user-centered designs improve your user engagement,
                conversion rates, and customer loyalty.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Design2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Responsive design
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We make mobile-first responsive designs that allow users to
                access your digital products anywhere, anytime.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Design3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Data-driven strategy
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                With data-driven research and insights, we create measurable,
                impact design experiences..
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.ipadWrapper1}>
              <Grid
                style={{ marginLeft: "-38px" }}
                sx={serviceStyle.iPadWrapper2}
                container
                columnSpacing={{ xs: 1, sm: 3, md: 6 }}>

                <Grid item component="img" src={Figma} />
                <Grid item component="img" src={Diamond} />
                <Grid item component="img" src={Ai} />
                <Grid item component="img" src={Xd} />
                <Grid item component="img" src={M} />
                <Grid item component="img" src={W} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* Mobile */}
      <Hidden smUp>
        <Grid id="uxui" sx={mobileStyle.mobileWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.rightImage}
              component="img"
              src={UXUILogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              DESIGN EMPOWERING SUCCESS
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              UX/UI Design
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We craft user-friendly interface designs for cloud-based
              software solutions, combining aesthetic appeal with data-driven
              strategies customized to meet your business needs.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Design1} />
              <Typography sx={serviceStyle.heading3}>
                Happy users, thriving businesses
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our user-centered designs improve your user engagement,
                conversion rates, and customer loyalty.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Design2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Responsive design
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We make mobile-first responsive designs that allow users to
                access your digital products anywhere, anytime.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Design3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Data-driven strategy
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                With data-driven research and insights, we create measurable,
                impact design experiences..
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.mobileWrapper1}>
              <Grid
                sx={serviceStyle.mobileWrapper2}
                container
                columnSpacing={{ xs: 1 }}
              >
                <Grid item component="img" src={Figma} />
                <Grid item component="img" src={Diamond} />
                <Grid item component="img" src={Ai} />
                <Grid item component="img" src={Xd} />
                <Grid item component="img" src={M} />
                <Grid item component="img" src={W} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default UXUI;
