import React from "react";
import Typography from "@mui/material/Typography";
import styles from "./styles";
import Card from "../Card/Card";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Circle from "../../Assets/images/Circle.jpg";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Cloud from "../../Assets/images/Cloud.svg";
import Quality from "../../Assets/images/Quality.svg";
import Arrow from "../../Assets/images/Arrow-1.svg";
import Mobile from "../../Assets/images/Mobile.svg";
import Devops from "../../Assets/images/Devops.svg";
import UXUI from "../../Assets/images/UXUI.svg";
import mobileStyle from "../mobileStyle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from 'react-magic-slider-dots';
import "react-magic-slider-dots/dist/magic-dots.css";
import "./dots.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const data = [
  {
    title1: `Cloud-based Software \nDevelopment`,
    // title1: "Cloud-based Software Development",
    title2:
      "We architect adjustable and adaptable cloud-based software solutions that helps companies stand out among the competition.",
    svg: Cloud,
  },
  {
    title1: `Mobile App  \nDevelopment`,
    title2:
      "With the power of cloud technology, we design and create cross-platform mobile that allowing effortless users interaction. ",
    svg: Mobile,
  },
  {
    title1: `DevOps and \nCI/CD`,
    title2:
      "We adopt DevOps culture and continuous integration and continuous delivery (CI/CD) for a smooth development and reliable deployment.",
    svg: Devops,
  },
  {
    title1: `Quality Assurance \nand Testing`,
    title2:
      "Our quality assurance and testing methods provide extensive test coverage to validate cloud-based software solutions.",
    svg: Quality,
  },
  {
    title1: `UX/UI  \nDesign`,
    title2:
      "We turn ideas into engaging experiences that enthrall, inspire, and produce results with a sharp eye for a user-centered product. ",
    svg: UXUI,
  },
];

export default function Service() {
  const sliderSettingsForMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} dotsClass={'slick-dots'} />;
    },
  };

  // const appendDots = () => {
  //   return (
  //     <Box sx={styles.rightArrow}>
  //       <IconButton aria-label="forward">
  //         <ArrowBackIosNewIcon onClick={previous} sx={styles.customArrow} />
  //       </IconButton>
  //       <IconButton aria-label="forward">
  //         <ArrowForwardIosIcon onClick={next} sx={styles.customArrow} />
  //       </IconButton>
  //     </Box>
  //   );
  // };

  return (
    <>
      <Hidden mdDown>
        <Grid item sx={styles.service}>
          <Grid container sx={styles.wrapper}>
            <Grid item xs={5} sx={styles.leftGrid}>
              <Typography sx={styles.leftGridHeading1}>Our services</Typography>
              <Typography sx={styles.leftGridHeading2}>
                Empower with <br /> innovation
              </Typography>
            </Grid>
            <Grid item xs={7} sx={styles.rightGrid}>
              <Grid sx={styles.rightGridWrapper}>
                <Box sx={styles.circleImage} component="img" src={Circle} />
                <Grid item style={{ marginLeft: "20px" }}>
                  <Typography sx={styles.rightGridHeading3}>
                    100+ Technologies
                  </Typography>
                  <Typography sx={styles.rightGridHeading4}>
                    We work on React, Angular, Node.js, Java, C#, DevOps, CI/CD,
                    Figma, Adobe and more
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={styles.rightGridWrapper}>
                <Box sx={styles.circleImage} component="img" src={Circle} />
                <Grid style={{ marginLeft: "20px" }}>
                  <Typography sx={styles.rightGridHeading3}>
                    Your data security matters
                  </Typography>
                  <Typography sx={styles.rightGridHeading4}>
                    We prioritize the confidentiality and protection of your
                    data security.
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={styles.rightGridWrapper}>
                <Box sx={styles.circleImage} component="img" src={Circle} />
                <Grid style={{ marginLeft: "20px" }}>
                  <Typography sx={styles.rightGridHeading3}>
                    All-in-one tech stop
                  </Typography>
                  <Typography sx={styles.rightGridHeading4}>
                    Our experts in various areas can assist your business from
                    design, build to maintenance.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 1 */}
          <Grid container spacing={4} sx={styles.wrapper1}>
            {/* Cloud-based Software development */}
            <Grid item lg={4} md={4}>
              <MuiCard sx={styles.card1}>
                <CardContent style={{ marginTop: "20px" }}>
                  <Box sx={styles.serviceImage} component="img" src={Cloud} />
                  <Typography sx={styles.serviceHeading1}>
                    Cloud-based Software Development
                  </Typography>
                  <Typography sx={styles.serviceHeading2}>
                    We architect adjustable and adaptable cloud-based software solutions that helps companies stand out among the competition.
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid>

            <Grid item lg={4} md={4}>
              {/* Mobile App Development */}
              <MuiCard sx={styles.card1}>
                <CardContent style={{ marginTop: "20px" }}>
                  <Box sx={styles.serviceImage} component="img" src={Mobile} />
                  <Typography sx={styles.serviceHeading1}>
                    Mobile App <br /> Development
                  </Typography>
                  <Typography sx={styles.serviceHeading2}>
                    With the power of cloud technology, we design and create cross-platform mobile that allowing effortless users interaction.
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid>

            <Grid item lg={4} md={4} sx={styles.wrapper3}>
              <Grid item >
                <Typography sx={styles.heading}>
                  If you can dream it,
                  <br /> we can build it.
                </Typography>
                <Grid item sx={styles.wrapper2}>
                  <Typography
                    variant="service"
                    component="a"
                    href="/servicePage"
                    sx={styles.learnMore}
                  >
                    Learn More
                  </Typography>
                  <Box
                    component="img"
                    src={Arrow}
                    style={{ marginLeft: "10px", marginTop: "30px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 2 */}

          <Grid container spacing={4} sx={styles.wrapper4}>
            <Grid item lg={4} md={4}>
              {/* DevOps and CI/CD */}
              <MuiCard sx={styles.card1}>
                <CardContent>
                  <Box sx={styles.serviceImage} component="img" src={Devops} />
                  <Typography sx={styles.serviceHeading1}>
                    DevOps and <br /> CI/CD
                  </Typography>
                  <Typography sx={styles.serviceHeading2}>
                    We adopt DevOps culture and continuous integration&
                    continuous delivery (CI/CD) for a smooth development and
                    reliable deployment.
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid>
            <Grid item lg={4} md={4}>
              {/* Quality Assurance and Testing */}
              <MuiCard sx={styles.card1}>
                <CardContent>
                  <Box sx={styles.serviceImage} component="img" src={Quality} />
                  <Typography sx={styles.serviceHeading1}>
                    Quality Assurance and Testing
                  </Typography>
                  <Typography sx={styles.serviceHeading2}>
                    Our quality assurance and testing methods provide extensive
                    test coverage to validate cloud-based software solutions.
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid>

            <Grid item lg={4} md={4}>
              {/* UX/UI  Design */}
              <MuiCard sx={styles.card1}>
                <CardContent>
                  <Box sx={styles.serviceImage} component="img" src={UXUI} />
                  <Typography sx={styles.serviceHeading1}>
                    UX/UI <br /> Design
                  </Typography>
                  <Typography sx={styles.serviceHeading2}>
                    We turn ideas into engaging experiences that enthrall,
                    inspire, and produce results with a sharp eye for a
                    user-centered product.
                  </Typography>
                </CardContent>
              </MuiCard>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}
      <Hidden mdUp smDown>
        <Grid item sx={mobileStyle.wrapper}>
          <Grid item sx={styles.ipadleftGrid}>
            <Typography sx={styles.leftGridHeading1}>Our services</Typography>
            <Typography sx={styles.leftGridHeading2}>
              Empower with innovation
            </Typography>
          </Grid>
          <Grid item sx={styles.ipadrightGrid}>
            <Grid item sx={styles.rightGridWrapper}>
              <Box sx={styles.ipadcircleImage} component="img" src={Circle} />
              <Grid item style={{ marginTop: "15px", marginLeft: "20px" }}>
                <Typography sx={styles.rightGridHeading3}>
                  100+ Technologies
                </Typography>
                <Typography sx={styles.rightGridHeading4}>
                  We work on React, Angular, Node.js, Java, C#, DevOps, CI/CD,
                  Figma, Adobe and more
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={styles.rightGridWrapper}>
              <Box sx={styles.ipadcircleImage} component="img" src={Circle} />
              <Grid item style={{ marginTop: "15px", marginLeft: "20px" }}>
                <Typography sx={styles.rightGridHeading3}>
                  Your data security matters
                </Typography>
                <Typography sx={styles.rightGridHeading4}>
                  We prioritize the confidentiality and protection of your data
                  security.
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={styles.rightGridWrapper}>
              <Box sx={styles.ipadcircleImage} component="img" src={Circle} />
              <Grid item style={{ marginTop: "15px", marginLeft: "20px" }}>
                <Typography sx={styles.rightGridHeading3}>
                  All-in-one tech stop
                </Typography>
                <Typography sx={styles.rightGridHeading4}>
                  Our experts in various areas can assist your business from
                  design, build to maintenance.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* 1 */}
        <Grid container spacing={4} sx={styles.ipadwrapper1}>
          {/* Cloud-based Software development */}
          <Grid item md={6} sm={6}>
            <MuiCard sx={styles.ipadcard} >
              <CardContent style={{ marginTop: "20px" }}>
                <Box sx={styles.ipadserviceImage} component="img" src={Cloud} />
                <Typography sx={styles.ipadserviceHeading1}>
                  Cloud-based Software Development
                </Typography>
                <Typography sx={styles.ipadserviceHeading2}>
                  we architect adjustable and adaptable cloud-based software
                  solutions that helps companies stand out among the
                  competition.
                </Typography>
              </CardContent>
            </MuiCard>
          </Grid>

          <Grid item md={6} sm={6} sx={styles.ipadwrapper3}>
            <Typography sx={styles.ipadheading}>
              If you can dream it,
              <br /> we can build it.
            </Typography>
            <Grid item sx={styles.wrapper2}>
              <Typography
                variant="service"
                component="a"
                href="/servicePage"
                sx={styles.ipadlearnMore}
              >
                Learn More
              </Typography>
              <Box
                component="img"
                src={Arrow}
                style={{ marginLeft: "10px", marginTop: "30px" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={styles.ipadwrapper1}>
          <Grid item md={6} sm={6}>
            <MuiCard sx={styles.ipadcard} >
              <CardContent>
                <Box
                  sx={styles.ipadserviceImage}
                  component="img"
                  src={Mobile}
                />
                <Typography sx={styles.ipadserviceHeading1}>
                  Mobile App <br /> Development
                </Typography>
                <Typography sx={styles.ipadserviceHeading2}>
                  With the power of cloud technology, we design and create
                  cross-platform mobile that allowing effortless users
                  interaction.
                </Typography>
              </CardContent>
            </MuiCard>
          </Grid>
          <Grid item md={6} sm={6}>
            <MuiCard sx={styles.ipadcard} >
              <CardContent>
                <Box
                  sx={styles.ipadserviceImage}
                  component="img"
                  src={Devops}
                />
                <Typography sx={styles.ipadserviceHeading1}>
                  DevOps and <br /> CI/CD
                </Typography>
                <Typography sx={styles.ipadserviceHeading2}>
                  We adopt DevOps culture and continuous integration& continuous
                  delivery (CI/CD) for a smooth development and reliable
                  deployment.
                </Typography>
              </CardContent>
            </MuiCard>
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={styles.ipadwrapper1}>
          <Grid item md={6} sm={6}>
            <MuiCard sx={styles.ipadcard} >
              <CardContent>
                <Box
                  sx={styles.ipadserviceImage}
                  component="img"
                  src={Quality}
                />
                <Typography sx={styles.ipadserviceHeading1}>
                  Quality Assurance and Testing
                </Typography>
                <Typography sx={styles.ipadserviceHeading2}>
                  Our quality assurance and testing methods provide extensive
                  test coverage to validate cloud-based software solutions.
                </Typography>
              </CardContent>
            </MuiCard>
          </Grid>

          {/* UX/UI  Design */}
          <Grid item md={6} sm={6}>
            <MuiCard sx={styles.ipadcard} >
              <CardContent>
                <Box sx={styles.ipadserviceImage} component="img" src={UXUI} />
                <Typography sx={styles.ipadserviceHeading1}>
                  UX/UI <br /> Design
                </Typography>
                <Typography sx={styles.ipadserviceHeading2}>
                  We turn ideas into engaging experiences that enthrall,
                  inspire, and produce results with a sharp eye for a
                  user-centered product.
                </Typography>
              </CardContent>
            </MuiCard>
          </Grid>
        </Grid>
      </Hidden>

      {/* Mobile */}

      <Hidden smUp>
        <Grid item sx={mobileStyle.wrapper}>
          <Grid item sx={styles.leftGrid}>
            <Typography sx={styles.leftGridHeading1}>Our services</Typography>
            <Typography sx={styles.leftGridHeading2}>
              Empower with innovation
            </Typography>
          </Grid>
          <Grid item sx={styles.rightGrid}>
            <Grid item sx={styles.rightGridWrapper}>
              <Box sx={styles.circleImage} component="img" src={Circle} />
              <Grid item style={{ marginTop: "15px" }}>
                <Typography sx={styles.rightGridHeading3}>
                  100+ Technologies
                </Typography>
                <Typography sx={styles.rightGridHeading4}>
                  We work on React, Angular, Node.js, Java, C#, DevOps, CI/CD,
                  Figma, Adobe and more
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={styles.rightGridWrapper}>
              <Box sx={styles.circleImage} component="img" src={Circle} />
              <Grid style={{ marginTop: "15px" }}>
                <Typography sx={styles.rightGridHeading3}>
                  Your data security matters
                </Typography>
                <Typography sx={styles.rightGridHeading4}>
                  We prioritize the confidentiality and protection of your data
                  security.
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={styles.rightGridWrapper}>
              <Box sx={styles.circleImage} component="img" src={Circle} />
              <Grid style={{ marginTop: "15px" }}>
                <Typography sx={styles.rightGridHeading3}>
                  All-in-one tech stop
                </Typography>
                <Typography sx={styles.rightGridHeading4}>
                  Our experts in various areas can assist your business from
                  design, build to maintenance.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={mobileStyle.mobileWrapper}>
          <Grid item sx={styles.mobileWrapper}>
            <Typography variant="h2" sx={styles.mobileTitle}>
              If you can dream it,
              <br /> we can build it
            </Typography>
            <Grid item sx={styles.mobileWrapper2}>
              <Typography
                variant="service"
                component="a"
                href="/servicePage"
                sx={styles.mobileLearnMore}
              >
                Learn More
              </Typography>
              <Box component="img" src={Arrow} style={{ marginLeft: "12px" }} />
            </Grid>
            <Grid item sx={styles.mobileSlider}>
              <Slider
                {...sliderSettingsForMobile}
              >
                {data.map((item, index) => {
                  return (
                    <Card
                      title1={item.title1}
                      title2={item.title2}
                      imgSrc={item.svg}
                      key={index}
                    />
                  );
                })}
              </Slider>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
}
