const styles = {
  link: (theme) => ({
    flexGrow: 1,
    justifyContent: "flex-end",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    letterSpacing: " 0.03em",
    color: "#243251",
    display: "flex",
    mr: "24px",
    textDecoration: "none",
    [theme.breakpoints.down('md')]: {
      marginTop: "0px",
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  }),

  mobileLink: (theme) => ({
    display: "flex",
    alignItems: "left",

    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    flexDirection: "column",
    marginLeft: "0px",
  }),

  button1: (theme) => ({
    /* My_button */
    fontFamily: "Montserrat",
    fontWeight: 600,
    marginTop: "-10px",
    width: "180px",
    height: "48px",
    textTransform: "capitalize",
    borderRadius: "8px",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#243251",
    size: "large",
    border: "2px solid",
    marginLeft: "48px",
    textDecoration: 'none',

    [theme.breakpoints.down('md')]: {
      marginBottom: "0px",
      paddingLeft: '10px',
      paddingRight: '0px',
    },
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: 'none',
    },

  }),

  button2: (theme) => ({
    /* My_button */
    fontFamily: "Montserrat",
    fontWeight: 500,
    marginTop: "10px",
    height: "48px",
    textTransform: "capitalize",
    fontSize: "20px",
    lineHeight: "120%",
    color: "#243251",
    fontStyle: "normal",
    letterSpacing: "0.6px",
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      marginBottom: "0px",
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: 'none',
    },
  }),

  deskbtnLink: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    padding: "0",
    marginRight: "44px",
    color: "#000000",
    marginBottom: "10px",
    textDecoration: "none",
    cursor: "pointer",
    letterSpacing: "0.48px",
    "&:hover": {
      color: "#686868",
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: "0px",
      paddingLeft: '10px',
      paddingRight: '0px',
    },
  }),

  mobbtnLink1: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    display: "flex",
    padding: "0",
    marginRight: "44px",
    color: "#000000",
    marginBottom: "10px",
    textDecoration: "none",
    cursor: "pointer",
    letterSpacing: "0.6px",
    lineHeight: "120%",
    "&:hover": {
      color: "#686868",
    },
  }),

  mobbtnLink2: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    color: "#3A4762",
    textDecoration: "none",
    cursor: "pointer",
    letterSpacing: "0.6px",
    textTransform: "capitalize",
  }),

  subLink: (theme) => ({
    textDecoration: "none",
    color: "#000000",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    lineHeight: "normal",
    paddingLeft: "10px",
    paddingRight: "24px",
    paddingTop: "12px",
    paddingBottom: "12px",
    whiteSpace: "pre-wrap",
    letterSpacing: "2%",
  }),

};

export default styles;
