const serviceStyle = {
  heading1: (theme) => ({
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "120%",
    marginBottom: "20px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "left",
      fontFamily: "Poppins",
      fontWeight: "500",
      letterSpacing: "0.28px",
      lineHeight: "160%",
    },
  }),

  iPadheading1: (theme) => ({
    textAlign: "start",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "120%",
    marginTop: "40px",
    letterSpacing: "0.54px",
  }),
  iPadheading2: (theme) => ({
    textAlign: "start",
    fontFamily: "Montserrat",
    fontSize: "43px",
    fontWeight: "700",
    lineHeight: "140%",
  }),

  reSizeCloud: (theme) => ({
    marginLeft: "120px",
    marginRight: "140px",
    marginTop: "160px",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      marginLeft: "24px",
      marginRight: "24px",
    },
  }),
  reSizeMobile: (theme) => ({
    marginLeft: "80px",
    marginRight: "150px",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      marginLeft: "-16px",
      marginRight: "24px",
    },
  }),
  reSizeDevop: (theme) => ({
    marginLeft: "122px",
    marginRight: "125px",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      marginLeft: "24px",
      marginRight: "5px",
    },
  }),
  reSizeQuality: (theme) => ({
    marginLeft: "80px",
    marginRight: "145px",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      marginLeft: "-16px",
      marginRight: "24px",
    },
  }),
  reSizeUxui: (theme) => ({
    marginLeft: "124px",
    marginRight: "125px",
    // Tablet
    [theme.breakpoints.down("lg")]: {
      marginLeft: "24px",
      marginRight: "5px",
    },
  }),
  heading2: (theme) => ({
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "37px",
    fontWeight: "700",
    lineHeight: "140%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "31px",
      textAlign: "left",
      fontFamily: "Poppins",
      fontWeight: "700",
      letterSpacing: "0.28px",
      lineHeight: "140%",
    },
  }),

  heading3: (theme) => ({
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "120%",
    marginTop: "7px",
    color: "#243251",
    letterSpacing: "0.54PX",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "120%",
      marginTop: "8px",
      color: "#243251",
      letterSpacing: "0.48PX",
    },
  }),

  heading4: (theme) => ({
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "9px",
    color: "#243251",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "160%",
      marginTop: "7px",
      color: "#243251",
      letterSpacing: "0.48PX",
    },
  }),

  wrapper: (theme) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: "120px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  }),

  iPadwrapper: (theme) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: "120px",
    marginRight: "20px",
  }),

  wrapperRight: (theme) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: "100px",
  }),

  cardLeft: (theme) => ({
    paddingLeft: "60px",
    paddingTop: "90px",
    paddingBottom: "90px",
    paddingRight: "60px",
    width: "100%",
    height: "100%",
    marginRight: "-80px",
    background: '#FFF',
    boxShadow: '-6.33784px 9.95946px 36.21622px 0px rgba(173, 175, 176, 0.35), -18.10811px -18.10811px 45.27027px 0px #F1F7FF',
    // boxShadow:
    //   "-20px -20px 50px #F1F7FF, -7px 11px 40px rgba(173, 175, 176, 0.35)",
    borderRadius: "26px",
  }),

  cardContentLeft: (theme) => ({
    padding: 0,
    textAlign: "left",
    paddingTop: "0",
    paddingBottom: "0",
  }),

  cardRight: (theme) => ({
    width: "100%",
    height: "100%",
    marginLeft: "-80px",
    paddingTop: "90px",
    paddingBottom: "90px",
    paddingRight: "60px",
    paddingLeft: "60px",
    background: '#FFF',
    boxShadow: '-6.33784px 9.95946px 36.21622px 0px rgba(173, 175, 176, 0.35), -18.10811px -18.10811px 45.27027px 0px #F1F7FF',
    // boxShadow:
    //   "-20px -20px 50px #F1F7FF, -7px 11px 40px rgba(173, 175, 176, 0.35)",
    borderRadius: "26px",
  }),
  cardContentRight: (theme) => ({
    // marginLeft: '120px',
    // marginRight: '200px',
    textAlign: "right",
    // marginTop: '90px',
    // width: '826px',

    // backgroundColor: 'aquamarine',
    paddingTop: "0",
    paddingBottom: "0px",
    // paddingRight: '60px',
    // paddingLeft: '60px',
    // marginBottom: '-20px',
    // marginBottom: '90px',
    // paddingBottom: 0,
  }),

  leftHeading1: (theme) => ({
    textAlign: "left",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    // marginTop: "-10px",
    letterSpacing: "3px",
    textDecoration: "uppercase",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontFamily: "Poppins",
      fontSize: "10px",
      fontWeight: "500",
      color: "#243251",
      marginTop: "50px",
      letterSpacing: "2.5px",
    },
  }),

  leftHeading2: (theme) => ({
    textAlign: "left",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "120%",
    marginTop: "20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontFamily: "Poppins",
      fontSize: "25px",
      fontWeight: "700",
      color: "#243251",
      marginTop: "20px",
      letterSpacing: "0.5px",
      lineHeight: "120%",
      textTransform: "capitalize",
    },
  }),

  leftHeading3: (theme) => ({
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    marginTop: "5px",
  }),

  leftHeading4: (theme) => ({
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: "22.5px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "40px",
    marginBottom: "-20px",

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontFamily: "Montserrat",
      fontSize: "16px",
      color: "#243251",
      marginTop: "20px",
      lineHeight: "160%",
    },
  }),

  rightHeading1: (theme) => ({
    textAlign: "right",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "16px",
    // marginTop: "20px",
    letterSpacing: "3px",
  }),

  rightHeading2: (theme) => ({
    textAlign: "right",
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    lineHeight: "120%",
    marginTop: "20px",
  }),
  rightHeading3: (theme) => ({
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "22.5px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "5px",
  }),
  rightHeading4: (theme) => ({
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "22.5px",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "60px",
    marginBottom: "-20px",
  }),

  leftImage: (theme) => ({
    height: "190px",
    width: "190px",
    position: "relative",
    alignSelf: "center",
    justifySelf: "center",
  }),

  rightImage: (theme) => ({
    height: "190px",
    width: "190px",
    position: "relative",
    alignSelf: "center",
    justifySelf: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      width: "100px",
      position: "relative",
      left: "-20px",
      top: "50px",
    },
  }),

  iPadrightImage: (theme) => ({
    height: "190px",
    width: "190px",
    marginLeft: "-42px",
  }),

  image: (theme) => ({
    marginLeft: "-10px",
    width: "85px",
    height: "85px",
    boxShadow: "0px 6.46856px 12.9371px rgba(157, 175, 198, 0.2",
  }),

  toolHeading: (theme) => ({
    textAlign: "center",
    fontSize: "22px",
    fontFamily: "Poppins",
    fontWeight: "500",
    lineHeight: "120%",
    marginTop: "80px",
    color: "#4D5460"
  }),

  toolWrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: "50px",
  }),

  // MOBILE
  mobileHeading: (theme) => ({
    textAlign: "left",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    lineHeight: "160%",
    marginTop: "50px",
    letterSpacing: "0.2px",
    textTransform: "capitalize",
    color: "#4D5460"
  }),

  ipadWrapper1: (theme) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    maxWidth: "100%"

  }),

  mobileWrapper1: (theme) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    maxWidth: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-12px"
    },
  }),
  mobileWrapper2: (theme) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
    marginBottom: "20px",
    marginRight: "24px",

    // maxWidth: "80px",
    // maxHeight: "80px",
  }),

  iPadWrapper2: (theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    marginBottom: "20px",
    maxWidth: "100%",
    alignSelf: "left",
    justifySelf: "left",
    justifyContent: "left",
  }),

  serviceWrapper: (theme) => ({
    marginTop: "150px",
    marginLeft: "24px"
  }),
};

export default serviceStyle;
