import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Components/Header";
import HomePage from "./Pages/HomePage";
import ServicePage from "./Pages/ServicePage";
import Footer from "./Components/Footer";
import Box from "@mui/material/Box";


const App = () => {
  return (
    <BrowserRouter>
      <Box
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/servicePage" element={<ServicePage />} />
        </Routes>
        <Footer />
      </Box>
    </BrowserRouter>
  );
};

export default App;
