import React from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Navbar from "../Navbar/Navbar";

const Drawer = ({ open, onClose }) => {
  return (
    <MuiDrawer
      PaperProps={{
        sx: { width: { xl: "100%", xs: "85%" } },
      }}
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <Box sx={{ paddingLeft: '25px', paddingRight: '25px', paddingTop: '37px', paddingBottom: '37px' }}>
        <IconButton onClick={onClose} >
          <CloseIcon color="primary" />
        </IconButton>
        <Box sx={{ marginTop: "37px", paddingBottom: '10px' }}>
          <Navbar onClose={onClose} />
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
