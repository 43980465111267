import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Famoeats from "./../../Assets/images/Company/Famoeats.svg";
import Quiggle from "./../../Assets/images/Company/Quiggle.svg";
import Techhive from "./../../Assets/images/Company/TechHive.svg";
import AbacusNext from "./../../Assets/images/Company/AbacusNext.svg";
import BStock from "./../../Assets/images/Company/BStock.svg";
import Caret from "./../../Assets/images/Company/CARET.svg";
import Omnitracs from "./../../Assets/images/Company/Omnitracs.svg";
import styles from "./styles";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

const Client = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid container sx={styles.client}>
          <Grid sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Typography sx={styles.wrapper}>Our Clients</Typography>
            <Grid sx={styles.clients}>
              <Grid component="img" sx={styles.clientLogo} src={Techhive} />
              <Grid component="img" sx={styles.clientLogo} src={Caret} />
              <Grid component="img" sx={styles.clientLogo} src={Quiggle} />
              <Grid component="img" sx={styles.clientLogo} src={AbacusNext} />
              <Grid component="img" sx={styles.clientLogo} src={BStock} />
              <Grid component="img" sx={styles.famoeatsLogo} src={Famoeats} />
              <Grid component="img" sx={styles.clientLogo} src={Omnitracs} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}
      <Hidden mdUp smDown>
        <Grid container sx={styles.mobileClient} >
          <Typography sx={styles.wrapper}>Our Clients</Typography>
          <Grid container sx={styles.ipadClients} >
            <Grid component="img" sx={styles.ipadclientLogo} src={Techhive} />
            <Grid component="img" sx={styles.ipadclientLogo} src={Caret} />
            <Grid component="img" sx={styles.ipadclientLogo} src={Quiggle} />
            <Grid component="img" sx={styles.ipadclientLogo} src={AbacusNext} />
            <Grid component="img" sx={styles.ipadclientLogo} src={BStock} />
            <Grid component="img" sx={styles.ipadclientLogo} src={Famoeats} />
            <Grid component="img" sx={styles.ipadclientLogo} src={Omnitracs} />
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container sx={styles.mobileClient}>
          <Grid sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Typography sx={styles.mobileWrapper}>Our Clients</Typography>
            <Grid
              container
              direction="row"
              sx={styles.mobileClients}
            >
              <Box component="img" sx={styles.mobileClientLogo} src={Techhive} />
              <Box
                component="img"
                sx={styles.mobileClientLogo}
                src={Caret}
              />
              <Box component="img" sx={styles.mobileClientLogo} src={Omnitracs} />
            </Grid>
            <Grid
              container
              direction="row"
              sx={styles.mobileClients}
            >
              <Box component="img" sx={styles.mobileClientLogo} src={Quiggle} />
              <Box
                component="img"
                sx={styles.mobileClientLogo}
                src={AbacusNext}
              />
              <Box component="img" sx={styles.famoeatsLogo} src={Famoeats} />
              <Box component="img" sx={styles.mobileClientLogo} src={BStock} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Client;
