
const mobileStyle = {
    wrapper: (theme) => ({
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginTop: '90px',
        marginBottom: '90px',
        marginLeft: '24px',
        marginRight: '24px',

    }),
    mobileWrapper: (theme) => ({
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginTop: '90px',
        marginBottom: '90px',
        marginLeft: '24px',
        marginRight: '24px',
    }),
    ipadWrapper: (theme) => ({
        justifyContent: 'left',
        alignItems: 'left',
        textAlign: 'left',
        marginTop: '200px',
        marginBottom: '60px',
        marginLeft: '24px',
        marginRight: '24px',
    }),
}

export default mobileStyle