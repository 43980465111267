import React from "react";
import serviceStyle from "./serviceStyle";
import mobileStyle from "../mobileStyle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Mobile1 from "../../Assets/images/WebDevlopment-Image/Mobile1.svg";
import Mobile2 from "../../Assets/images/WebDevlopment-Image/Mobile2.svg";
import Mobile3 from "../../Assets/images/WebDevlopment-Image/Mobile3.svg";
import Swift from "../../Assets/images/tools/Swift.svg";
import Node from "../../Assets/images/tools/Nodejs.svg";
import Apple from "../../Assets/images/tools/Apple.svg";
import Xcode from "../../Assets/images/tools/xcode.svg";
import SQL from "../../Assets/images/tools/SQL.svg";
import Android from "../../Assets/images/tools/Android.svg";
import Angular from "../../Assets/images/tools/Angular.svg";
import React1 from "../../Assets/images/tools/Reactnew.png";
import Hidden from "@mui/material/Hidden";
import MobileLogo from "../../Assets/images/ServiceIcons/mobile.png";

const Mobile = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid id="mobile" sx={serviceStyle.reSizeMobile}>
          {/* Top */}
          <Grid sx={serviceStyle.wrapperRight}>
            {/* Left*/}
            <Box sx={serviceStyle.leftImage} component="img" src={MobileLogo} />

            {/* Right*/}
            <Box sx={serviceStyle.cardRight}>
              <CardContent sx={serviceStyle.cardContentRight}>
                <Typography sx={serviceStyle.rightHeading1}>
                  CROSS-PLATFORM EXCELLENCE
                </Typography>
                <Typography sx={serviceStyle.rightHeading2}>
                  Mobile App Development
                </Typography>

                <Typography sx={serviceStyle.rightHeading4}>
                  We specialize in cross-platform mobile app development using
                  cloud technology to deliver seamless experiences across
                  multiple devices and operating systems.
                </Typography>
              </CardContent>
            </Box>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapperRight} style={{ marginLeft: "40px" }}>
            <Grid style={{ width: "33%" }}>
              <Box component="img" sx={serviceStyle.image} src={Mobile1} />
              <Typography sx={serviceStyle.heading3}>
                Enhance Business Outcome
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Responsive mobile apps will improve brand perception, increase
                retention rates, and tap into a larger customer pool.
              </Typography>
            </Grid>
            <Grid style={{ width: "33%" }}>
              <Box component="img" src={Mobile2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Secure Cloud Integration{" "}
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use robust security measures to protect user data and provide
                secure access to cloud resources.
              </Typography>
            </Grid>
            <Grid style={{ width: "33%" }}>
              <Box component="img" src={Mobile3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Continuous Iterations
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We collaborate, listen to attentive feedback, and iterate fast
                to build products that satisfy your needs.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.toolHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.toolWrapper}>
              <Box component="img" src={React1} />
              <Box component="img" src={Swift} />
              <Box component="img" src={Node} />
              <Box component="img" src={Angular} />
              <Box component="img" src={Apple} />
              <Box component="img" src={Xcode} />
              <Box component="img" src={SQL} />
              <Box component="img" src={Android} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}
      <Hidden mdUp smDown>
        <Grid id="mobile" sx={mobileStyle.ipadWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.iPadrightImage}
              component="img"
              src={MobileLogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              CROSS-PLATFORM EXCELLENCE
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              Mobile App Development
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We specialize in cross-platform mobile app development using cloud
              technology to deliver seamless experiences across multiple devices
              and operating systems.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.iPadwrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Mobile1} />
              <Typography sx={serviceStyle.heading3}>
                Enhance Business Outcome
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Responsive mobile apps will improve brand perception, increase
                retention rates, and tap into a larger customer pool.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Mobile2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Secure Cloud Integration
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use robust security measures to protect user data and provide
                secure access to cloud resources.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Mobile3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Continuous Iterations
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We collaborate, listen to attentive feedback, and iterate fast
                to build products that satisfy your needs.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.ipadWrapper1}>
              <Grid
                container
                sx={serviceStyle.iPadWrapper2}
                columnSpacing={{ xs: 1, sm: 2, md: 8 }}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Grid item component="img" src={React1} />
                <Grid item component="img" src={Swift} />
                <Grid item component="img" src={Node} />
                <Grid item component="img" src={Angular} />
                <Grid item component="img" src={Apple} />
                <Grid item component="img" src={Xcode} />
                <Grid item component="img" src={SQL} />
                <Grid item component="img" src={Android} />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* Mobile */}

      <Hidden smUp>
        <Grid id="mobile" sx={mobileStyle.mobileWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.rightImage}
              component="img"
              src={MobileLogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              CROSS-PLATFORM EXCELLENCE
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              Mobile App Development
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We specialize in cross-platform mobile app development using cloud
              technology to deliver seamless experiences across multiple devices
              and operating systems.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Mobile1} />
              <Typography sx={serviceStyle.heading3}>
                Enhance Business Outcome
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Responsive mobile apps will improve brand perception, increase
                retention rates, and tap into a larger customer pool.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Mobile2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Secure Cloud Integration
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use robust security measures to protect user data and provide
                secure access to cloud resources.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Mobile3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Continuous Iterations
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We collaborate, listen to attentive feedback, and iterate fast
                to build products that satisfy your needs.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>

            <Grid sx={serviceStyle.mobileWrapper1}>
              <Grid
                sx={serviceStyle.mobileWrapper2}
                container
                columnSpacing={{ xs: 1 }}
              >
                <Grid item component="img" src={React1} />
                <Grid item component="img" src={Swift} />
                <Grid item component="img" src={Node} />
                <Grid item component="img" src={Angular} />
                <Grid item component="img" src={Apple} />
                <Grid item component="img" src={Xcode} />
                <Grid item component="img" src={SQL} />
                <Grid item component="img" src={Android} />

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Mobile;
