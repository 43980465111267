const styles = {
  wrapper: (theme) => ({
    width: "100%",
    height: "100%",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      alignItems: "left",
      justifyContent: "left",
      textAlign: "left",
    },
  }),

  cardWrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  }),
  cardArrow: (theme) => ({
    width: "21px",
    marginLeft: "15px",
  }),
  cards: (theme) => ({
    width: "350px",
    height: "400px",
    padding: "10px",
  }),
  image: (theme) => ({
    width: "81px",
    height: "73px",
    padding: 0,
  }),
  cardTitle1: (theme) => ({
    /* cardTitle */
    fontFamily: "Montserrat",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "120%",
    display: "flex",
    alignItems: "center",
    letterSpacing: " 0.03em",
    color: "#323441",
    justifyContent: "center",
    textAlign: "center",
  }),
  cardTitle2: (theme) => ({
    /* cardTitle */
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "160%",
    display: "flex",
    alignItems: "center",
    letterSpacing: " 0.03em",
    color: "#323441",
    justifyContent: "center",
    textAlign: "center",
  }),
  btn: (theme) => ({
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#181E2B",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      alignItems: "left",
      justifyContent: "left",
      textAlign: "left",
    },
  }),
  listItem: (theme) => ({
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#181E2B",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      alignItems: "left",
      justifyContent: "left",
      textAlign: "left",
    },
  }),

  // Mobile
  mobileWrapper: (theme) => ({
    padding: '5px',

  }),
  mobileImage: (theme) => ({
    width: "81px",
    height: "60px",
    // padding: 0,
    alignItems: "left",
    justifyContent: "left",
  }),
  mobileCards: (theme) => ({
    justifyContent: "left",
    alignItems: "left",
    textAlign: "left",
    minWidth: '250px',
    maxWidth: "300px",
    height: "360px",
    borderRadius: '20px',
    background: '#ffff',

  }),
  mobileSingleCard: (theme) => ({
    // Inside card
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
    marginBottom: "20px",
    gap: "24px",
    display: "flex",
    flexDirection: "column",
  }),
  mobileCardTitle1: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "120%",
    display: "flex",
    alignItems: "left",
    color: "#243251",
    justifyContent: "left",
    textAlign: "left",
  }),
  mobileCardTitle2: (theme) => ({
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "160%",
    display: "flex",
    alignItems: "left",
    color: "#323441",
    justifyContent: "left",
    textAlign: "left",
  }),
  mobileCardWrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
  }),
  mobileCardArrow: (theme) => ({
    width: "21px",
    marginLeft: "15px",
  }),
};

export default styles;
