import React from "react";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import BtnNav from "./BtnNav";
import Button from "@mui/material/Button";
import styles from "./styles";
import { Typography } from "@mui/material";
import { HashLink } from "react-router-hash-link";
const pages = [
  { title: "Home", arrow: false, link: "/", id: '1' },
  { title: "Services", arrow: true, link: "/servicePage", id: '2' },
];

const Navbar = ({ onClose }) => {
  return (
    <>
      <Hidden smDown>
        <Box
          sx={{
            flexGrow: 1,
            display: { lg: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Box sx={styles.link}>
            {pages.map((page, index) => (
              <BtnNav page={page} key={index} />
            ))}
            <HashLink to="/#startUp"
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
            >
              <Button
                variant="outlined"
                sx={styles.button1}
              >
                About Us
              </Button>
            </HashLink>
          </Box>
        </Box>
      </Hidden>

      {/* MOBILE */}
      <Hidden smUp>
        <Box
          sx={{
            flexGrow: 1,
            display: { lg: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <Box sx={styles.mobileLink}>
            {pages.map((page, index) => (
              <BtnNav page={page} key={index} onClose={onClose} />
            ))}
            <HashLink to="/#startUp"
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })}
              style={{ textDecoration: "none" }}>
              <Typography
                onClick={onClose}
                sx={styles.button2}
              >
                About Us
              </Typography>
            </HashLink>
          </Box>
        </Box>
      </Hidden>
    </>
  );
};

export default Navbar;
