const styles = {
  value: (theme) => ({
    marginTop: '50px',
    marginBottom: '100px',
    paddingLeft: '120px',
    paddingRight: '120px',

    // Tablet
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },

  }),

  valueContainer: (theme) => ({
    marginTop: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    textTransform: "capitalize",
  }),
  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    // MOBILE
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  }),
  box: (theme) => ({
    paddingTop: "15px",
    textAlign: "left",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "0px",
      marginBottom: "15px",
    },
  }),
  title: (theme) => ({
    fontFamily: "Poppins",
    lineHeight: "140%",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    display: "flex",
    letterSpacing: " 0.03em",
    color: "#243251",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "100px",
    marginBottom: "50px",

    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Poppins",
      fontWeight: "700",
      fontSize: "25px",
      lineHeight: "140%",
      letterSpacing: "0.03em",
    },
  }),
  images: (theme) => ({
    width: "45px",
    height: "45px",
    top: "55px",
    left: "0px",
  }),
  heading: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "22.5px",
    lineHeight: "120%",
    display: "flex",
    letterSpacing: " 0.66px",
    color: "#243251",
    marginTop: "5px",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Poppins",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "20px",
      textAlign: "left",
      marginTop: "15px",
    },
  }),

  subheading: (theme) => ({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    letterSpacing: "3px",
    color: "#4D5460",
    textTransform: "uppercase",
    marginTop: "18px",

    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      letterSpacing: "0.18em",
      textAlign: "left",
      marginTop: "10px",
    },
  }),
  description: (theme) => ({
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "160%",
    display: "flex",
    color: "#243251",
    marginTop: "18px",
    textTransform: "none",
    // MOBILE
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "160%",
      marginTop: "10px",
      textAlign: "left",
    },
  }),

  mobileTitle: (theme) => ({
    alignItems: "left",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "140%",
    letterSpacing: "0.03em",
    // marginTop: '20px',
    marginBottom: "30px",
    textTransform: "capitalize",
  }),
};

export default styles;
