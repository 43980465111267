import React from "react";
import serviceStyle from "./serviceStyle";
import mobileStyle from "../mobileStyle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Testing1 from "../../Assets/images/WebDevlopment-Image/Testing1.svg";
import Testing2 from "../../Assets/images/WebDevlopment-Image/Testing2.svg";
import Testing3 from "../../Assets/images/WebDevlopment-Image/Testing3.svg";
import QualityLogo from "../../Assets/images/ServiceIcons/quality.png";
import Hidden from "@mui/material/Hidden";
import Playwright from "../../Assets/images/tools/Playwright.svg";
import Postman from "../../Assets/images/tools/Postman.svg";
import Js from "../../Assets/images/tools/JS.svg";
import Cypress from "../../Assets/images/tools/Cypress.svg";
import Xray from "../../Assets/images/tools/Xray.svg";
import TestRail from "../../Assets/images/tools/TestRail.svg";
import Selenium from "../../Assets/images/tools/Selenium.svg";

const Qualityassurance = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid id="quality" sx={serviceStyle.reSizeQuality}>
          {/* Top */}
          <Grid sx={serviceStyle.wrapperRight}>
            {/* Left*/}
            <Box
              sx={serviceStyle.leftImage}
              component="img"
              src={QualityLogo}
            />

            {/* Right*/}
            <Box sx={serviceStyle.cardRight}>
              <CardContent sx={serviceStyle.cardContentRight}>
                <Typography sx={serviceStyle.rightHeading1}>
                  HIGH TEST COVERAGE
                </Typography>
                <Typography sx={serviceStyle.rightHeading2}>
                  Quality Assurance and Testing
                </Typography>

                <Typography sx={serviceStyle.rightHeading4}>
                  We use thorough testing methodologies to ensure high test
                  coverage and comprehensive validation of cloud-based software
                  solutions.
                </Typography>
              </CardContent>
            </Box>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapperRight} style={{ marginLeft: "40px" }}>
            <Grid style={{ width: "33%" }}>
              <Box component="img" sx={serviceStyle.image} src={Testing1} />
              <Typography sx={serviceStyle.heading3}>
                Data Security & Compliance
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We ensure your software meets the highest standards of data
                protection and regulatory compliance
              </Typography>
            </Grid>
            <Grid style={{ width: "33%" }}>
              <Box component="img" src={Testing2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Various Testing Strategies
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use load, stress, and scalability testing to validate
                performance and optimize system resources.
              </Typography>
            </Grid>
            <Grid style={{ width: "33%" }}>
              <Box component="img" src={Testing3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Vigilant Bug Detect & Prevent
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our meticulous testing and automation minimize errors in your
                software through continuous monitoring.
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Typography sx={serviceStyle.toolHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.toolWrapper}>
              <Box component="img" src={Playwright} />
              <Box component="img" src={Selenium} />
              <Box component="img" src={Xray} />
              <Box component="img" src={Cypress} />
              <Box component="img" src={TestRail} />
              <Box component="img" src={Js} />
              <Box component="img" src={Postman} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}

      <Hidden mdUp smDown>
        <Grid id="quality" sx={mobileStyle.ipadWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.iPadrightImage}
              component="img"
              src={QualityLogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              HIGH TEST COVERAGE
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              Quality Assurance and Testing
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We use thorough testing methodologies to ensure high test coverage
              and comprehensive validation of cloud-based software solutions.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.iPadwrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Testing1} />
              <Typography sx={serviceStyle.heading3}>
                Data Security & Compliance
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We ensure your software meets the highest standards of data
                protection and regulatory compliance
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Testing2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Various Testing Strategies
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use load, stress, and scalability testing to validate
                performance and optimize system resources.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Testing3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Vigilant Bug Detect & Prevent
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our meticulous testing and automation minimize errors in your
                software through continuous monitoring.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.ipadWrapper1}>
              <Grid
                sx={serviceStyle.iPadWrapper2}
                container
                columnSpacing={{ xs: 1, sm: 1, md: 6 }}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Grid item component="img" src={Playwright} />
                <Grid item component="img" src={Selenium} />
                <Grid item component="img" src={Cypress} />
                <Grid item component="img" src={Xray} />
                <Grid item component="img" src={Js} />
                <Grid item component="img" src={Postman} />
                <Grid item component="img" src={TestRail} />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* Mobile */}

      <Hidden smUp>
        <Grid id="quality" sx={mobileStyle.mobileWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.rightImage}
              component="img"
              src={QualityLogo}
              style={{ position: "relative", left: "-20px" }}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              HIGH TEST COVERAGE
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              Quality Assurance and Testing
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We use thorough testing methodologies to ensure high test coverage
              and comprehensive validation of cloud-based software solutions.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Testing1} />
              <Typography sx={serviceStyle.heading3}>
                Data Security & Compliance
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We ensure your software meets the highest standards of data
                protection and regulatory compliance
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Testing2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Various Testing Strategies
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use load, stress, and scalability testing to validate
                performance and optimize system resources.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Testing3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Vigilant Bug Detect & Prevent
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our meticulous testing and automation minimize errors in your
                software through continuous monitoring.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.MobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.mobileWrapper1}>
              <Grid
                sx={serviceStyle.mobileWrapper2}
                container
                columnSpacing={{ xs: 1 }}
              >
                <Grid item component="img" src={Playwright} />
                <Grid item component="img" src={Selenium} />
                <Grid item component="img" src={Xray} />
                <Grid item component="img" src={Cypress} />
                <Grid item component="img" src={TestRail} />
                <Grid item component="img" src={Js} />
                <Grid item component="img" src={Postman} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Qualityassurance;
