const styles = {
  wrapper: (theme) => ({
    marginTop: '50px',
    marginBottom: '100px',
    // display: "flex",
    // flexDirection: "row",
    marginLeft: '120px',
    marginRight: '120px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: "24px",
      marginRight: '0px',
    },
  }),


  leftGrid: (theme) => ({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    marginTop: "105px",

  }),

  rightGrid: (theme) => ({
    // width: "50%",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "space-evenly",
    // marginRight: '10px',
  }),

  title: (theme) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: ' 0.03em',
    color: ' #243251',
    textTransform: 'capitalize',

    [theme.breakpoints.up('lg')]: {
      fontSize: "36px",
      fontWeight: "600",
      lineHeight: '50px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: "25px",
      lineHeight: '30px',
    },
    // MOBILE
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      display: 'flex',
      letterSpacing: '0.15px',
      fontSize: "32px",
      lineHeight: '120%',
      textTransform: 'capitalize',
      marginBottom: "20px",
    },
  }),

  subTitle: (theme) => ({
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.03px',
    color: '#243251',
    marginTop: "5px",

    // MOBILE
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: '24px',
      display: 'flex',
      letterSpacing: '0.48px',
      color: '#4D5460',
      marginTop: "5px",
    },
  }),


  images: (theme) => ({
    position: "relative",
    width: '45px',
    height: '45px',
    top: "55px",
    left: "0px",
    [theme.breakpoints.up('lg')]: {
      width: '45px',
      height: '45px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30px',
      height: '30px',
    },

  }),

  heading: (theme) => ({
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '28px',
    lineHeight: '40px',
    color: '#243251',
    marginLeft: "55px",
    // MOBILE
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      fontSize: '18px',
      lineHeight: '40px',
    },
  }),
  subHeading: (theme) => ({
    marginLeft: "55px",
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '20px',
    color: '#18191F',
    // MOBILE
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      fontSize: "12px",
      lineHeight: "14px",
    },
  }),

  // MOBILE
  topGrid: () => ({
    alignItems: 'left',
    textAlign: 'left',
    justifyContent: "space-between",
    width: '100%',
    display: "flex",
    flexDirection: "column",
    // marginTop: "105px",
    marginBottom: "30px",
  }),

  mobileImage: (theme) => ({
    position: "relative",
    width: '45px',
    height: '45px',
    left: "-10px",
  }),
  boxStyle: () => ({
    marginTop: '20px',
    marginLeft: "22px",
    alignItems: 'left',
    flexDirection: "column",
    justifyContent: "space-between",
  }),

};

export default styles;
