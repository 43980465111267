import React from "react";
import serviceStyle from "./serviceStyle";
import mobileStyle from "../mobileStyle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Devops1 from "../../Assets/images/WebDevlopment-Image/Devops1.svg";
import Devops2 from "../../Assets/images/WebDevlopment-Image/Devops2.svg";
import Devops3 from "../../Assets/images/WebDevlopment-Image/Devops3.svg";
import Devops4 from "../../Assets/images/WebDevlopment-Image/Devops4.svg";
import Azure from "../../Assets/images/tools/Azure.svg";
import AWS from "../../Assets/images/tools/AWS.svg";
import GCP from "../../Assets/images/tools/Gcp.svg";
import Azurep from "../../Assets/images/tools/azure-pipelines.svg";
import Jenkins from "../../Assets/images/tools/Jenkins.svg";
import Sandbox from "../../Assets/images/tools/Sandbox.svg";
import Greensqr from "../../Assets/images/tools/Greensqr.svg";
// import GCF from "../../Assets/images/tools/Gcf.svg";
import Azzure from "../../Assets/images/tools/Azure.svg";
// import CICD from "../../Assets/images/tools/cicd.svg";
// import Awslambda from "../../Assets/images/tools/AWS-lambda.svg";
import GCF from "../../Assets/images/tools/gfc1.svg";
import CICD from "../../Assets/images/tools/cicd1.svg";
import Awslambda from "../../Assets/images/tools/aws1.svg";
import Bluecomp from "../../Assets/images/tools/blueomp.svg";
import Travis from "../../Assets/images/tools/travis.svg";
import Azurefunc from "../../Assets/images/tools/azurefunctions1.svg";
import Hidden from "@mui/material/Hidden";
import DevopLogo from "../../Assets/images/ServiceIcons/devop1.svg";

const Devops = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid id="devops" sx={serviceStyle.reSizeDevop}>
          {/* Top */}
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <Grid sx={serviceStyle.wrapper} item xs={12}>
              {/* Left */}
              <Box sx={serviceStyle.cardLeft}>
                <CardContent sx={serviceStyle.cardContentLeft}>
                  <Typography sx={serviceStyle.leftHeading1}>
                    STREAMLINED DEVELOPMENT
                  </Typography>
                  <Typography sx={serviceStyle.leftHeading2}>
                    DevOps and CI/CD
                  </Typography>

                  <Typography sx={serviceStyle.leftHeading4}>
                    We adopt a DevOps culture and implement continuous
                    integration and delivery (CI/CD) practices to streamline the
                    development process and enable rapid and reliable
                    deployment.
                  </Typography>
                </CardContent>
              </Box>
              {/* Right*/}
              <Box
                sx={serviceStyle.rightImage}
                component="img"
                src={DevopLogo}
              />
            </Grid>
          </Grid>

          {/* Bottom */}
          <Grid
            sx={serviceStyle.wrapper}
            style={{ marginLeft: "0px", marginRight: "28px" }}
          >
            <Grid style={{ width: "50%", marginRight: "20px" }}>
              <Box component="img" sx={serviceStyle.image} src={Devops1} />
              <Typography sx={serviceStyle.heading3}>
                Leading Cloud Solutions
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We utilize leading cloud platforms robust features and services
                to optimize scalability, performance, and security.
              </Typography>
            </Grid>
            <Grid style={{ width: "50%" }}>
              <Box component="img" src={Devops2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Infrastructure-as-Code
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use tools like (AWS Cloud Formation, Microsoft ARM, etc…) to
                provision and manage cloud resources efficiently, ensuring
                consistency and scalability across environments.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={serviceStyle.wrapper}
            style={{ marginLeft: "0px", marginRight: "25px" }}
          >
            <Grid style={{ width: "50%", marginRight: "20px" }}>
              <Box component="img" sx={serviceStyle.image} src={Devops3} />
              <Typography sx={serviceStyle.heading3}>
                Automated Testing and Deployment
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We integrate automated testing and deployment pipelines into our
                CI/CD workflows to facilitate efficient and reliable testing,
                building, and deployment processes.
              </Typography>
            </Grid>
            <Grid style={{ width: "50%" }}>
              <Box component="img" src={Devops4} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Serverless Computing (Function-as-a-Service)
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                Our approach incorporates serverless architecture to deliver
                cost-efficient scalability for proof-of-concept (POC) products
                and startup initiatives.
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.toolHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.toolWrapper}>
              <Box component="img" src={Azzure} />
              <Box component="img" src={AWS} />
              <Box component="img" src={GCP} />
              <Box component="img" src={Azurep} />
              <Box component="img" src={Jenkins} />
              <Box component="img" src={Sandbox} />
            </Grid>

            <Grid sx={serviceStyle.toolWrapper}>
              <Box component="img" src={Travis} />
              <Box component="img" src={Greensqr} />
              <Box component="img" src={Azurefunc} />
              <Box component="img" src={GCF} />
              <Box component="img" src={CICD} />
              <Box component="img" src={Awslambda} />
              <Box component="img" src={Bluecomp} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}
      <Hidden mdUp smDown>
        <Grid id="devops" sx={mobileStyle.ipadWrapper}>
          <Grid>
            <Box
              sx={serviceStyle.iPadrightImage}
              component="img"
              src={DevopLogo}
            />
            <Typography sx={serviceStyle.leftHeading1}>
              STREAMLINED DEVELOPMENT
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              DevOps and CI/CD
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We adopt a DevOps culture and implement continuous integration and
              delivery (CI/CD) practices to streamline the development process
              and enable rapid and reliable deployment.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.iPadwrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Devops1} />
              <Typography sx={serviceStyle.heading3}>
                Leading Cloud Solutions
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We utilize leading cloud platforms robust features and services
                to optimize scalability, performance, and security.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Devops2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Infrastructure-as-Code
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use tools like (AWS Cloud Formation, Microsoft ARM, etc…) to
                provision and manage cloud resources efficiently, ensuring
                consistency and scalability across environments.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Devops3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Automated Testing and Deployment
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We integrate automated testing and deployment pipelines into our
                CI/CD workflows to facilitate efficient and reliable testing,
                building, and deployment processes.
              </Typography>
              <Grid style={{ width: "100%", marginTop: "30px" }}>
                <Box component="img" src={Devops4} sx={serviceStyle.image} />
                <Typography sx={serviceStyle.heading3}>
                  Serverless Computing (Function-as-a-Service)
                </Typography>
                <Typography sx={serviceStyle.heading4}>
                  Our approach incorporates serverless architecture to deliver
                  cost-efficient scalability for proof-of-concept (POC) products
                  and startup initiatives.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>
            <Grid sx={serviceStyle.ipadWrapper1}>
              <Grid
                sx={serviceStyle.iPadWrapper2}
                container
                columnSpacing={{ xs: 1, sm: 3, md: 8 }}
              >
                <Grid item component="img" src={Azure} />
                <Grid item component="img" src={GCP} />
                <Grid item component="img" src={Azurep} />
                <Grid item component="img" src={Jenkins} />
                <Grid item component="img" src={Sandbox} />
              </Grid>

              <Grid
                sx={serviceStyle.iPadWrapper2}
                container
                columnSpacing={{ xs: 1, sm: 3, md: 4 }}
              >
                <Grid item component="img" src={Travis} />
                <Grid item component="img" src={GCF} />
                <Grid item component="img" src={CICD} />
                <Grid item component="img" src={Awslambda} />
                <Grid item component="img" src={Greensqr} />
                <Grid item component="img" src={Azurefunc} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* Mobile */}

      <Hidden smUp>
        <Grid id="devops" sx={mobileStyle.mobileWrapper}>
          <Grid>
            <Box sx={serviceStyle.rightImage} component="img" src={DevopLogo} />
            <Typography sx={serviceStyle.leftHeading1}>
              STREAMLINED DEVELOPMENT
            </Typography>
            <Typography sx={serviceStyle.leftHeading2}>
              DevOps and CI/CD
            </Typography>

            <Typography sx={serviceStyle.leftHeading4}>
              We adopt a DevOps culture and implement continuous integration and
              delivery (CI/CD) practices to streamline the development process
              and enable rapid and reliable deployment.
            </Typography>
          </Grid>

          {/* Bottom */}
          <Grid sx={serviceStyle.wrapper} item xs={12}>
            <Grid style={{ width: "100%" }}>
              <Box component="img" sx={serviceStyle.image} src={Devops1} />
              <Typography sx={serviceStyle.heading3}>
                Leading Cloud Solutions
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We utilize leading cloud platforms robust features and services
                to optimize scalability, performance, and security.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Devops2} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Infrastructure-as-Code
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We use tools like (AWS Cloud Formation, Microsoft ARM, etc…) to
                provision and manage cloud resources efficiently, ensuring
                consistency and scalability across environments.
              </Typography>
            </Grid>
            <Grid style={{ width: "100%", marginTop: "30px" }}>
              <Box component="img" src={Devops3} sx={serviceStyle.image} />
              <Typography sx={serviceStyle.heading3}>
                Automated Testing and Deployment
              </Typography>
              <Typography sx={serviceStyle.heading4}>
                We integrate automated testing and deployment pipelines into our
                CI/CD workflows to facilitate efficient and reliable testing,
                building, and deployment processes.
              </Typography>
              <Grid style={{ width: "100%", marginTop: "30px" }}>
                <Box component="img" src={Devops4} sx={serviceStyle.image} />
                <Typography sx={serviceStyle.heading3}>
                  Serverless Computing (Function-as-a-Service)
                </Typography>
                <Typography sx={serviceStyle.heading4}>
                  Our approach incorporates serverless architecture to deliver
                  cost-efficient scalability for proof-of-concept (POC) products
                  and startup initiatives.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Typography sx={serviceStyle.mobileHeading}>
              Tools we expert in
            </Typography>

            <Grid sx={serviceStyle.mobileWrapper1}>
              <Grid
                sx={serviceStyle.mobileWrapper2}
                container
                columnSpacing={{ xs: 1 }}
              >
                <Grid item component="img" src={Azure} />
                <Grid item component="img" src={GCP} />
                <Grid item component="img" src={Azurep} />
                <Grid item component="img" src={Jenkins} />
                <Grid item component="img" src={Sandbox} />
                <Grid item component="img" src={Travis} />
                <Grid item component="img" src={GCF} />
                <Grid item component="img" src={CICD} />
                <Grid item component="img" src={Awslambda} />
                <Grid item component="img" src={Greensqr} />
                <Grid item component="img" src={Azurefunc} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Devops;
