const styles = {
  appBar: (theme) => ({
    background: "transparent",
    boxShadow: "none",
    paddingTop: "20px",
    paddingBottom: '20px',
    paddingLeft: "120px",
    paddingRight: "120px",
    backgroundColor: "white",

    [theme.breakpoints.down('lg')]: {
      paddingLeft: '24px',
      paddingRight: '0px',
    },

    // MOBILE
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  }),
  container: (theme) => ({
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      margin: 0,
    },
  }),

  logoDesk: (theme) => ({
    marginRight: '2px',
    display: { xs: "none", sm: "flex" },
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  }),

  logoMob: (theme) => ({
    width: '100px',
    alignItems: "left",
    display: { xs: "flex", sm: "none" },
    flexGrow: 1,
    marginLeft: '-100px',
  }),
};

export default styles;
