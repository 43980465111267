import React from "react";
import { Container } from "@mui/system";
import Hero from "../Components/Hero/Hero";
import Service from "../Components/Service/Service";
import Startup from "../Components/Startup/Startup";
import Value from "../Components/Value/Value";
import Achievements from "../Components/Achievements/Achievements";
import Client from "../Components/Client/Client";


const HomePage = () => {
  return (
    <>
      <Container maxWidth="xxl"
        sx={{ paddingLeft: '0px', paddingRight: '0px', overflowX: "hidden", }}
      >
        <Hero />
        <Startup />
        <Value />
        <Service />
        <Achievements />
        <Client />
      </Container>
    </>
  );
};

export default HomePage;