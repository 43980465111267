import React from "react";
import Box from "@mui/material/Box";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";

import styles from "./styles";

const Card = ({ title1, title2, imgSrc }) => {
  return (
    <>
      <Hidden smUp>
        <Box sx={styles.mobileWrapper}>
          <MuiCard sx={styles.mobileCards} >
            <CardContent sx={styles.mobileSingleCard}>
              <Box component="img" sx={styles.mobileImage} src={imgSrc} />
              <Typography sx={styles.mobileCardTitle1}>{title1}</Typography>
              <Typography sx={styles.mobileCardTitle2}>{title2}</Typography>
            </CardContent>
          </MuiCard>
        </Box>
      </Hidden>
    </>
  );
};

export default Card;
