import { createTheme } from "@mui/material/styles";
// import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import backgroundImage from "../Assets/images/background.svg"
// const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: "#181E2B",
    },
    slateBlue: {
      main: "#0359AB",
    },
    background: {
      default: backgroundImage
    },
    width: {
      default: '100 %'
    },
  },
  root: {
    overflowX: 'hidden',
  },
  fontFamily: 'Lato, sans-serif , Roboto',
});

export default theme;
