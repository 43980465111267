import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Styles from "./styles";
import Circle from "../../Assets/images/Circle.jpg";
import mobileStyle from "../mobileStyle";

const Value = () => {
  return (
    <>
      <Hidden mdDown>
        <Grid
          sx={Styles.value}
        >
          <Grid id="value" container sx={Styles.valueContainer}>
            <Typography sx={Styles.title}>The value we live by</Typography>
            <Grid container spacing={{ sm: 12, md: 6 }} sx={Styles.wrapper}>
              {/* 1st */}
              <Grid item xs={4} sx={Styles.box}>
                <Box component="img" sx={Styles.images} src={Circle} />
                <Typography sx={Styles.heading}>Value Optimization</Typography>
                <Typography sx={Styles.subheading}>
                  Cost-Effective Excellence
                </Typography>
                <Typography sx={Styles.description}>
                  Our goal is to deliver cost-effective excellence maximizes
                  value for you, ensuring exceptional outcomes that align with
                  your goals, budget, and long-term success.
                </Typography>
              </Grid>
              {/* 2nd */}
              <Grid item xs={4} sx={Styles.box}>
                <Box component="img" sx={Styles.images} src={Circle} />
                <Typography sx={Styles.heading}>Innovation</Typography>
                <Typography sx={Styles.subheading}>
                  Igniting creative solutions
                </Typography>
                <Typography sx={Styles.description}>
                  Through imaginative thinking and cutting-edge technologies, we
                  craft unique and visionary solutions that redefine
                  possibilities and drive business success.
                </Typography>
              </Grid>
              {/* 3rd */}
              <Grid item xs={4} sx={Styles.box}>
                <Box component="img" sx={Styles.images} src={Circle} />
                <Typography sx={Styles.heading}>Collaboration</Typography>
                <Typography sx={Styles.subheading}>
                  Synergistic Teamwork Approach
                </Typography>
                <Typography sx={Styles.description}>
                  Our synergistic teamwork approach fosters collaboration,
                  bringing together diverse expertise, ideas, and perspectives
                  to create cohesive solutions that exceed expectations.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}
      <Hidden mdUp smDown>
        <Grid id="value" sx={mobileStyle.wrapper}>
          <Typography sx={Styles.mobileTitle}>The value we Live by</Typography>
          <Grid container sx={Styles.wrapper}>
            <Grid sx={Styles.box}>
              <Box component="img" sx={Styles.images} src={Circle} />
              <Typography sx={Styles.heading}>Innnovation</Typography>
              <Typography sx={Styles.subheading}>
                Igniting creative solutions
              </Typography>
              <Typography sx={Styles.description}>
                Through imaginative thinking and cutting-edge technologies, we
                craft unique and visionary solutions that redefine possibilities
                and drive business success.
              </Typography>
            </Grid>
            <Grid sx={Styles.box}>
              <Box component="img" sx={Styles.images} src={Circle} />
              <Typography sx={Styles.heading}>Collaboration</Typography>
              <Typography sx={Styles.subheading}>
                Synergistic Teamwork Approach
              </Typography>
              <Typography sx={Styles.description}>
                Our synergistic teamwork approach fosters collaboration,
                bringing together diverse expertise, ideas, and perspectives to
                create cohesive solutions that exceed expectations.
              </Typography>
            </Grid>
            <Grid sx={Styles.box}>
              <Box component="img" sx={Styles.images} src={Circle} />
              <Typography sx={Styles.heading}>Value Optimization</Typography>
              <Typography sx={Styles.subheading}>
                Cost-Effective Excellence
              </Typography>
              <Typography sx={Styles.description}>
                Our goal is to deliver cost-effective excellence maximizes value
                for you, ensuring exceptional outcomes that align with your
                goals, budget, and long-term success.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid id="value" sx={mobileStyle.wrapper}>
          <Typography sx={Styles.mobileTitle}>The value we Live by</Typography>
          <Grid container sx={Styles.wrapper}>
            <Grid sx={Styles.box}>
              <Box component="img" sx={Styles.images} src={Circle} />
              <Typography sx={Styles.heading}>Innnovation</Typography>
              <Typography sx={Styles.subheading}>
                Igniting creative solutions
              </Typography>
              <Typography sx={Styles.description}>
                Through imaginative thinking and cutting-edge technologies, we
                craft unique and visionary solutions that redefine possibilities
                and drive business success.
              </Typography>
            </Grid>
            <Grid sx={Styles.box}>
              <Box component="img" sx={Styles.images} src={Circle} />
              <Typography sx={Styles.heading}>Collaboration</Typography>
              <Typography sx={Styles.subheading}>
                Synergistic Teamwork Approach
              </Typography>
              <Typography sx={Styles.description}>
                Our synergistic teamwork approach fosters collaboration,
                bringing together diverse expertise, ideas, and perspectives to
                create cohesive solutions that exceed expectations.
              </Typography>
            </Grid>
            <Grid sx={Styles.box}>
              <Box component="img" sx={Styles.images} src={Circle} />
              <Typography sx={Styles.heading}>Value Optimization</Typography>
              <Typography sx={Styles.subheading}>
                Cost-Effective Excellence
              </Typography>
              <Typography sx={Styles.description}>
                Our goal is to deliver cost-effective excellence maximizes value
                for you, ensuring exceptional outcomes that align with your
                goals, budget, and long-term success.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Value;
