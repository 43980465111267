import React from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import Client from "../Components/Client";
import Cloud from "../Components/AllServices/Cloud";
import Qualityassurance from "../Components/AllServices/Qualityassurance";
import Mobile from "../Components/AllServices/Mobile";
import Devops from "../Components/AllServices/Devops";
import UXUI from "../Components/AllServices/UXUI";
import Hidden from "@mui/material/Hidden";

const ServicePage = () => {
  return (
    <>

      {/* Desktop */}
      <Hidden mdDown>
        <Box
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "10px",
            overflowX: "hidden"
          }}
        >
          <Container
            maxWidth="xxl"
            sx={{ paddingLeft: "0px", paddingRight: "0px",
              //  paddingBottom: "100px", 
                }}
          >
            <Cloud />
            <Mobile />
            <Devops />
            <Qualityassurance />
            <UXUI />
          </Container>
          <Client />
        </Box>
      </Hidden>

      {/* iPad */}

      <Hidden mdUp smDown>
        <Box
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "10px",
            overflowX: "hidden"
          }}
        >
          <Container
            sx={{ paddingLeft: 0, paddingRight: 0, 
              // paddingBottom: "100px",
                }}
          >
            <Cloud />
            <Mobile />
            <Devops />
            <Qualityassurance />
            <UXUI />
            <Client />
          </Container>
          
        </Box>
      </Hidden>

      {/* MOBILE */}
      <Hidden smUp>
        <Box
          style={{
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "10px",
            overflowX: "hidden"
          }}
        >
          <Container
            sx={{ paddingLeft: 0, paddingRight: 0, 
              // paddingBottom: "100px",
                }}
          >
            <Cloud />
            <Mobile />
            <Devops />
            <Qualityassurance />
            <UXUI />
          </Container>
          <Client />
        </Box>
      </Hidden>
    </>
  );
};

export default ServicePage;
