import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import Styles from "./styles";
import Circle from "../../Assets/images/Circle.jpg";
import mobileStyle from '../mobileStyle';

const Achievements = () => {
  return (
    <>
      <Hidden lgDown>
        <Grid container columns={{ xs: 4, sm: 8, md: 6 }} sx={Styles.wrapper}>
          <Grid item xs={6} sm={4} md={3} sx={Styles.leftGrid}>
            <Typography sx={Styles.title}>Our Achievements</Typography>
            <Typography sx={Styles.subTitle}>
              With our super powers we have reached this
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} sx={Styles.rightGrid}>
            <Grid>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>100+</Typography>
              <Typography sx={Styles.subHeading}>Technologies</Typography>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>15+</Typography>
              <Typography sx={Styles.subHeading}>Projects</Typography>
            </Grid>
            <Grid>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>6+</Typography>
              <Typography sx={Styles.subHeading}>Industries</Typography>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>3</Typography>
              <Typography sx={Styles.subHeading}>Countries</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      {/* iPad */}
      <Hidden lgUp smDown>
        <Grid container columns={{ xs: 4, sm: 8, md: 6 }} sx={Styles.wrapper}>
          <Grid item xs={6} sm={4} md={3} sx={Styles.leftGrid}>
            <Typography sx={Styles.title}>Our Achievements</Typography>
            <Typography sx={Styles.subTitle}>
              With our super powers we have reached this
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} sx={Styles.rightGrid}>
            <Grid>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>100+</Typography>
              <Typography sx={Styles.subHeading}>Technologies</Typography>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>15+</Typography>
              <Typography sx={Styles.subHeading}>Projects</Typography>
            </Grid>
            <Grid>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>6+</Typography>
              <Typography sx={Styles.subHeading}>Industries</Typography>
              <Box sx={Styles.images} component="img" src={Circle} />
              <Typography sx={Styles.heading}>3</Typography>
              <Typography sx={Styles.subHeading}>Countries</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid sx={mobileStyle.wrapper}>
          <Grid container sx={Styles.topGrid}>
            <Typography sx={Styles.title}>Our achievements</Typography>
            <Typography sx={Styles.subTitle}>
              With our super powers we have reached this
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
              <Box sx={Styles.mobileImage} component="img" src={Circle} />
              <Typography sx={Styles.heading}>100+</Typography>
              <Typography sx={Styles.subHeading}>Technologies</Typography>
            </Grid>
            <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
              <Box sx={Styles.mobileImage} component="img" src={Circle} />
              <Typography sx={Styles.heading}>6+</Typography>
              <Typography sx={Styles.subHeading}>Industries</Typography>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
              <Box sx={Styles.mobileImage} component="img" src={Circle} />
              <Typography sx={Styles.heading}>15+</Typography>
              <Typography sx={Styles.subHeading}>Projects</Typography>
            </Grid>
            <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
              <Box sx={Styles.mobileImage} component="img" src={Circle} />
              <Typography sx={Styles.heading}>3</Typography>
              <Typography sx={Styles.subHeading}>Countries</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default Achievements;






// import React from "react";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Hidden from "@mui/material/Hidden";
// import Grid from "@mui/material/Grid";
// import Styles from "./styles";
// import Circle from "../../Assets/images/Circle.jpg";
// import mobileStyle from '../mobileStyle';

// const Achievements = () => {
//   return (
//     <>
//       <Hidden smDown>
//         <Grid container sx={Styles.wrapper}>
//           <Grid item xs={6} sx={Styles.leftGrid}>
//             <Typography sx={Styles.title}>Our Achievements</Typography>
//             <Typography sx={Styles.subTitle}>
//               With our super powers we have reached this
//             </Typography>
//           </Grid>
//           <Grid item xs={6} sx={Styles.rightGrid}>
//             <Grid>
//               <Box sx={Styles.images} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>100+</Typography>
//               <Typography sx={Styles.subHeading}>Technologies</Typography>
//               <Box sx={Styles.images} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>15+</Typography>
//               <Typography sx={Styles.subHeading}>Projects</Typography>
//             </Grid>
//             <Grid>
//               <Box sx={Styles.images} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>6+</Typography>
//               <Typography sx={Styles.subHeading}>Industries</Typography>
//               <Box sx={Styles.images} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>3</Typography>
//               <Typography sx={Styles.subHeading}>Countries</Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Hidden>

//       <Hidden smUp>
//         <Grid sx={mobileStyle.wrapper} s>
//           <Grid container sx={Styles.topGrid}>
//             <Typography sx={Styles.title}>Our achievements</Typography>
//             <Typography sx={Styles.subTitle}>
//               With our super powers we have reached this
//             </Typography>
//           </Grid>
//           <Grid container>
//             <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
//               <Box sx={Styles.mobileImage} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>100+</Typography>
//               <Typography sx={Styles.subHeading}>Technologies</Typography>
//             </Grid>
//             <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
//               <Box sx={Styles.mobileImage} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>6+</Typography>
//               <Typography sx={Styles.subHeading}>Industries</Typography>
//             </Grid>
//           </Grid>
//           <Grid container >
//             <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
//               <Box sx={Styles.mobileImage} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>15+</Typography>
//               <Typography sx={Styles.subHeading}>Projects</Typography>
//             </Grid>
//             <Grid item xs container sx={Styles.boxStyle} direction="column" spacing={2}>
//               <Box sx={Styles.mobileImage} component="img" src={Circle} />
//               <Typography sx={Styles.heading}>3</Typography>
//               <Typography sx={Styles.subHeading}>Countries</Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Hidden>
//     </>
//   );
// };

// export default Achievements;
